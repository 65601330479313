import _ from 'lodash';

import { api, apiTimeout } from '@/utils/api'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat'; //To allow parsing with a custom format
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import memoryDriver from 'localforage-memoryStorageDriver';

import { getFirstFrameFromVideo } from '@/utils/media';

import { cloudUpload, cloudDone, cloudOffline, alert } from 'ionicons/icons';

const MODULE_VERSION = 9; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

const CATEGORY_SEPARATOR = '->';

const createMonthObject = (newMonth) => {
  let date = dayjs.utc(newMonth, 'MM.YYYY');
  return {
    month: newMonth,
    start: date.startOf('month').format('DD.MM.YYYY'),
    end: date.endOf('month').format('DD.MM.YYYY')
  }
}

const getDefaultState = () => {
  return {
    version: undefined,
    selectedDay: dayjs().format('DD.MM.YYYY'), //Default is today
    selectedTimespanDay: null, //By default only a single day is selected
    selectedMonth: createMonthObject(dayjs().format('MM.YYYY')), //Default is this month
    reportIndex: {},
    reportIndexLastFetchTime: null,
    reportUpdateRelations: {}, //Contains a key for each report that is either updated or updates another
    reportTypeIndex: {},
    mainCategories: {},
    reportUploadStatus: {}
  }
};

const NETWORK_ERRORS = [
  502, //Bad Gateway
  503, //Service Unavailable
  504 //Gateway Timeout
]

const calculateFileUploadTimeout = (totalFileSize) => {
  //Calculate fileSize in MB and assume a Minimum of 0,05MB/s = 20000ms/MB + add the regular timeout for text 
  return (Math.round((totalFileSize / (1024 * 1024))) * 20000) + parseInt(apiTimeout);
};

const uploadQueue = new Storage({
  name: '__uploadqueue',
  driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
});
uploadQueue.create();

const serializeFormData = function(data) {
	let obj = {};
	for (let [key, value] of data) {
		if (obj[key] !== undefined) {
			if (!Array.isArray(obj[key])) {
				obj[key] = [obj[key]];
			}
			obj[key].push(value);
		} else {
			obj[key] = value;
		}
	}
	return obj;
}

const unserializeFormData = function(data) {
	let formData = new FormData();
	for (let [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      for (let arrayValue of value) {
        formData.append(key, arrayValue);
      }
    } else {
      formData.append(key, value);
    }
	}
	return formData;
}

//Fallback option to save the report to in case the memory in uploadCache is not enough.
var inMemoryUploadQueue = {}; //Gets deleted on app restart or when browser needs memory!

//Create a cache for requests
const cache = new Storage({
  name: '__cache',
  driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB/*, Drivers.LocalStorage*/, memoryDriver._driver] //Skip the localStorage to not fill it up //TODO Check if memoryDriver works to store as last resort in RAM
});
cache.create();

//Create a cache for all files that are part of reports
const fileCache = new Storage({
  name: '__filecache',
  driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
});
fileCache.create();

const clearCacheStorage = function(excludeUploadQueue = false) {
  inMemoryUploadQueue = {};
  let clearPromises = [cache.clear(), fileCache.clear()];
  if (!excludeUploadQueue) clearPromises.push(uploadQueue.clear());
  return Promise.all(clearPromises);
}

export default {
  namespaced: true,

  state: getDefaultState,
  getters: {
    getLocationValues () {
      return ['stable', 'clinic'];
    },
    getReportIndex (state) {
      return state.reportIndex;
    },
    getReportIndexLastFetchTime (state) {
      return state.reportIndexLastFetchTime;
    },
    getRelatedReportList (state) { //Get the the report chain the given one updates or null if none is updated by this one
      return function(id) {
        let searchedId = id;
        let foundReport = null;
        let reportUpdateChain = [];

        let moveUp = true; //Move first up through the update chain to get the most recent report

        do {
          if (searchedId != null) {
            foundReport = state.reportUpdateRelations[searchedId];
          } else {
            foundReport = null;
          }

          if (foundReport != null) {
            if (moveUp) {
              searchedId = foundReport.updated;
              if (searchedId == null) { //If we have reached the top of the chain, set this to be next one to search for and move down
                searchedId = foundReport.id;
                moveUp = false;
              }
            } else { 
              reportUpdateChain.push(foundReport);
              searchedId = foundReport.updates;
            }
          }
        } while (foundReport != null);

        //If we don't find any relations, add at least this report for finding currently uploading reports
        if (reportUpdateChain.length <= 0) {
          if (state.reportIndex != null) {
            for (let month of Object.values(state.reportIndex)) {
              if (month.days != null) {
                for (let day of Object.values(month.days)) {
                  if (day.horses != null) {
                    for (let reports of Object.values(day.horses)) {
                      if (reports != null) {
                        for (let report of Object.values(reports)) {
                          if (report.id == id) reportUpdateChain.push(report);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (reportUpdateChain.length >= 1) {
          //Look up if the top report is currently being updated, if so add that ID to the top
          if (state.reportUploadStatus != null) {
            for (let [index, status] of Object.entries(state.reportUploadStatus)) {
              if (status != null && status.id != null && status.id == reportUpdateChain[0].id) {
                let uploadingReport = {...status.details, id: `U${index}`};
                reportUpdateChain.unshift(uploadingReport);
              }
            }
          }
        }

        if (reportUpdateChain.length <= 1) return null; //If the only report in the list is the given one, we also have no related reports - might happen because of deleted ones
        return reportUpdateChain;
      }
    },
    getReportTypeIndex (state) {
      return state.reportTypeIndex;
    },
    getReportTypeById (state) { //Search the ReportTypeIndex for an id that matches the searched id for any of the descriptors
      return function(id) {
        for (let [mainCategoryId, mainCategoryTypes] of Object.entries(state.reportTypeIndex)) {
          for (let [descriptor, typeInfo] of Object.entries(mainCategoryTypes)) {
            for (let version of Object.values(typeInfo.versions)) {
              if (version.id === id) {
                return { descriptor, type: version, main_category: mainCategoryId };
              }
            }
          }
        }
        return null;
      }
    },
    getSelectedDay (state) {
      return state.selectedDay;
    },
    getSelectedTimespanDay (state) {
      return state.selectedTimespanDay;
    },
    getSelectedMonth (state) {
      return state.selectedMonth;
    },
    getMainCategories (state) {
      return state.mainCategories;
    },
    getMainCategoryNamesById (state) {
      return function(id) {
        if (state.mainCategories[id]){
          return state.mainCategories[id].name;
        } else {
          return null;
        }
      }
    },
    getMainCategoryNamesOfReportTypeId (state) {
      return function(id) {
        for (let [mainCategoryId, mainCategoryTypes] of Object.entries(state.reportTypeIndex)) {
          for (let typeInfo of Object.values(mainCategoryTypes)) {
            for (let version of Object.values(typeInfo.versions)) {
              if (version.id === id) {
                let mainCategory = state.mainCategories[mainCategoryId];
                if (mainCategory) {
                  return mainCategory.name;
                } else {
                  return null;
                }
              }
            }
          }
        }
        return null;
      }
    },
    getReportUploadStatus (state) {
      return state.reportUploadStatus;
    },
    getReportUploadStatusUnfinished (state) {
      return ((state.reportUploadStatus != null) ? _.pickBy(state.reportUploadStatus, (value) => (value.finishedID == null)) : state.reportUploadStatus);
    },
    getReportsBeingUpdated (state) {
      if (state.reportUploadStatus == null) {
        return [];
      } else {
        //Create an array of all the ids that are in the upload status
        return Object.values(state.reportUploadStatus).map((status) => {
          return status.id;
        }).filter((id) => (id != null));
      }
    },
    getUploadStatusDesign (){
      return function(status) {
        if (status != null) {
          if (status.dataLost) {
            return {
              identifier: 'dataLost',
              color: '--ion-color-danger',
              shortColor: 'danger',
              icon: alert,
              text: 'data_lost'
            }
          } else if (status.progress >= 100 || status.finishedID != null) {
            return {
              identifier: 'uploaded',
              color: '--ion-color-success',
              shortColor: 'success',
              icon: cloudDone,
              text: 'uploaded'
            }
          } else if (status.progress >= 0 && status.progress < 100) {
            return {
              identifier: 'uploading',
              color: '--ion-color-success',
              shortColor: 'success',
              icon: cloudUpload,
              text: 'uploading'
            }
          } else if (status.stoppedRetry) {
            return {
              identifier: 'stoppedRetry',
              color: '--ion-color-warning',
              shortColor: 'warning',
              icon: alert,
              text: 'stopped_retry'
            }
          }
        }
        return {
          identifier: 'offline',
          color: '--ion-color-warning',
          shortColor: 'warning',
          icon: cloudOffline,
          text: 'retry_offline'
        }
      }
    },
    getStorageIndizes (){
      return Promise.allSettled([
        cache.keys().then((keys) => { return { name: 'cache', keys: (keys != null) ? keys.sort() : [] } }),
        uploadQueue.keys().then((keys) => { return { name: 'uploadQueue', keys: (keys != null) ? keys.sort() : [] } }),
        fileCache.keys().then((keys) => { return { name: 'fileCache', keys: (keys != null) ? keys.sort() : [] } }),
        (async () => Object.keys(inMemoryUploadQueue))().then((keys) => { return { name: 'inMemoryUploadQueue', keys: (keys != null) ? keys.sort() : [] } })
      ]).then((results) => _.filter(_.map(results, (result) => (result.value != null) ? result.value : undefined), (mappedResult) => mappedResult != null));
    }
  },
  mutations: {
    setSelectedDay (state, newDay) {
      state.selectedDay = newDay;
    },
    setSelectedTimespanDay (state, newDay) {
      if (newDay != null) {
        let newDateObject = dayjs.utc(newDay, 'DD.MM.YYYY');
        let selectedDayObject = dayjs.utc(state.selectedDay, 'DD.MM.YYYY');
        if (newDateObject != null && state.selectedDay != null && selectedDayObject.isSameOrBefore(newDateObject, 'day')) 
          state.selectedTimespanDay = newDay;
      } else {
        state.selectedTimespanDay = null;
      }
    },
    setSelectedMonth (state, newMonth) {
      state.selectedMonth = createMonthObject(newMonth);
    },
    setReportIndex (state, newIndex) {
      state.reportIndex = newIndex;
    },
    setReportIndexLastFetchTime (state, newTimestamp) {
      state.reportIndexLastFetchTime = newTimestamp;
    },
    addToReportIndex (state, newEntry) { //Sort entry in bucket represented by its date
      let reportEntry = {...newEntry};

      //Delete unnecessary fields, just in case
      delete reportEntry.fields;
      delete reportEntry.shares;
      delete reportEntry.analyses;

      if (reportEntry.updated == null){ //Only add the report to the index when it has not been updated by a newer one
        let currentTime = dayjs.utc(reportEntry.timestamp);
        
        let month = currentTime.format('MM.YYYY');
        if (!(month in state.reportIndex)) { //Add an empty object (bucket) inside a container, if no one exists for this month
          state.reportIndex[month] = { days: {} };
        }
        
        let day = currentTime.format('DD.MM.YYYY');
        if (!(day in state.reportIndex[month].days)) { //Add an empty object (bucket) inside a container with just a counter, if no one exists for this date
          state.reportIndex[month].days[day] = {count: 0, horses: {}};
        }

        let horse;
        if (reportEntry.horse == null){
          horse = null;
        } else {
          horse = reportEntry.horse;
        }
        if (!(horse in state.reportIndex[month].days[day].horses)) { //Add an empty object (bucket) for each horse, if it does not exist.
          state.reportIndex[month].days[day].horses[horse] = {};
        }

        //Save it in the corresponding bucket to override any existing report for this horse
        if (!(reportEntry.id in state.reportIndex[month].days[day].horses[horse])) { //Only count as a new report if it doesn't exist yet
          state.reportIndex[month].days[day].count++;
        }
        state.reportIndex[month].days[day].horses[horse][reportEntry.id] = reportEntry;
      }
    },
    removeReportsFromIndexAndRelations (state, ids) {
      if (Array.isArray(ids)){
        for (let month of Object.keys(state.reportIndex)) {
          for (let day of Object.keys(state.reportIndex[month].days)) {
            for (let horseId of Object.keys(state.reportIndex[month].days[day].horses)) {
              for (let reportId of Object.keys(state.reportIndex[month].days[day].horses[horseId])) {
                if (ids.includes(parseInt(reportId))) {
                  state.reportIndex[month].days[day].count--;
                  delete state.reportIndex[month].days[day].horses[horseId][reportId];
                }
              }
              if (Object.keys(state.reportIndex[month].days[day].horses[horseId]).length <= 0){ //If the horse has no more reports remaining, delete it
                delete state.reportIndex[month].days[day].horses[horseId];
              }
            }
            if (state.reportIndex[month].days[day].count <= 0) { //If we removed all reports from a day, remove this object too
              delete state.reportIndex[month].days[day];
            }
          }
          if (Object.keys(state.reportIndex[month].days) <= 0) { //If we removed all days from a month, remove this object too
            delete state.reportIndex[month];
          }
        }

        for (let id of ids) {
          delete state.reportUpdateRelations[id]; //Delete these ids from the update relations
        }
      }
    },
    setReportUpdateRelations (state, newRelations) {
      state.reportUpdateRelations = newRelations;
    },
    addReportUpdateRelation (state, newEntry) { 
      if (newEntry != null && newEntry.id != null && (newEntry.updates != null || newEntry.updated != null)){
        state.reportUpdateRelations[newEntry.id] = newEntry;
      }
    },
    removeReportIdFromIndex (state, id){
      for (const [month, monthBucket] of Object.entries(state.reportIndex)) {
        for (const [day, dayBucket] of Object.entries(monthBucket.days)) {
          for (const [horse, horseBucket] of Object.entries(dayBucket.horses)) {
            if (id in horseBucket) {
              delete state.reportIndex[month].days[day].horses[horse][id];
              state.reportIndex[month].days[day].count--;
              //If a horse has no reports, remove it
              if (Object.keys(state.reportIndex[month].days[day].horses[horse]).length <= 0) {
                delete state.reportIndex[month].days[day].horses[horse];
              }
              //If a day has no horses remove it
              if (Object.keys(state.reportIndex[month].days[day].horses).length <= 0) {
                delete state.reportIndex[month].days[day];
              }
              //If a month has no days remove it
              if (Object.keys(state.reportIndex[month].days).length <= 0) {
                delete state.reportIndex[month];
              }
            }
          }
        }
      }
    },
    setReportTypeIndex (state, newIndex) {
      state.reportTypeIndex = newIndex;
    },
    setMainCategories (state, newCategories) {
      state.mainCategories = newCategories;
    },
    addReportUploadStatus (state, newStatus) {
      if (newStatus != null && newStatus.index != null)
        state.reportUploadStatus[newStatus.index] = newStatus.status;
    },
    removeReportUploadStatus (state, index) {
      delete state.reportUploadStatus[index];
    },
    updateReportUploadProgress (state, newStatus) {
      if (newStatus != null && newStatus.index != null && newStatus.index in state.reportUploadStatus)
        state.reportUploadStatus[newStatus.index].progress = newStatus.progress;
    },
    setReportUploadFinishedID (state, { index, id }) {
      if (index != null && index in state.reportUploadStatus && id != null)
        state.reportUploadStatus[index].finishedID = id;
    },
    stopReportUploadRetry (state, index) {
      if (index in state.reportUploadStatus)
        state.reportUploadStatus[index].stoppedRetry = true;
    },
    setReportUploadDataLost (state, index) {
      if (index in state.reportUploadStatus)
        state.reportUploadStatus[index].dataLost = true;
    },
    checkVersion (state) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let uploadStatus = state.reportUploadStatus;
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState)){
            state[key] = undefined;
          }
        }
        state.version = MODULE_VERSION;
        let excludeUploadQueue = false;
        //If we have an unfinshed upload in the queue do not empty the uploadQueue
        if (uploadStatus != null) {
          uploadStatus = _.pickBy(uploadStatus, (value) => (value.finishedID == null));
          if (Object.keys(uploadStatus).length > 0) {
            Object.assign(state, { reportUploadStatus: uploadStatus });
            excludeUploadQueue = true;
          }
        }
        //Clear cache too
        clearCacheStorage(excludeUploadQueue);
      }
    },
    clearPersonalData (state) {
      let defaultState = {};
      Object.assign(defaultState, getDefaultState());
      state.reportIndex = defaultState.reportIndex;
      state.reportIndexLastFetchTime = defaultState.reportIndexLastFetchTime;
      state.reportUpdateRelations = defaultState.reportUpdateRelations;
      state.reportTypeIndex = defaultState.reportTypeIndex;
      state.mainCategories = defaultState.mainCategories;
      state.reportUploadStatus = defaultState.reportUploadStatus;
    }
  },
  actions: {
    updateSelectedDay (context, newDay) {
      context.commit('setSelectedDay', newDay);
    },
    updateSelectedTimespanDay (context, newDay) {
      context.commit('setSelectedTimespanDay', newDay);
    },
    updateSelectedMonth (context, newMonth) {
      context.commit('setSelectedMonth', newMonth);
    },
    fetchReportIndex (context) {
      let lastFetchTimestamp = context.getters.getReportIndexLastFetchTime;
      let currentTimestamp = dayjs().utc().toISOString();

      let filter = {};

      if (lastFetchTimestamp != null) {
        filter = {
          'updated_at_gte': lastFetchTimestamp
        }
      }
      
      return new Promise((resolve, reject) => {
        api
        .get('/reports', { params: filter })
        .then(response => { //Group by day and set to report index
          context.commit('setReportIndexLastFetchTime', currentTimestamp);
          for (let reportEntry of response.data){
            context.commit('addToReportIndex', reportEntry);
            context.commit('addReportUpdateRelation', reportEntry);
          }
          
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    updateSelectedMonthAndFetchIndex (context, newMonthObject) {
      return new Promise((resolve, reject) => {
        context.dispatch('updateSelectedMonth', newMonthObject)
        .then(() => context.dispatch('fetchReportIndex')) //TODO Only fetch if month changed, same for current report. Or maybe add some caching, like a rolling array for loaded ones. Refresh can be handled separately.
        .then(() => resolve())
        .catch(error => {
          reject(error);
        })
      });
    },
    fetchReportTypeIndex (context) { //TODO Make this also more efficient by only fetching new ones and updating the index - and also updating the cache - might not call watcher for fetching reportTypes in App when more efficient
      return new Promise((resolve, reject) => {
        api
        .get('/entry-types')
        .then(response => { //Use id as key in one object and Group ids by category in a separate object
          let newReportTypeIndex = {};
          
          for (let type of response.data){
            if (!(type.main_category in newReportTypeIndex)) { //Add an empty Object (bucket), if no one exists for this category
              newReportTypeIndex[type.main_category] = {};
            }
            if (!(type.descriptor in newReportTypeIndex[type.main_category])) { //Add an empty Object (bucket), if no one exists for this report type to store its versions and store the newest version
              newReportTypeIndex[type.main_category][type.descriptor] = { newest_version: -1, versions: {} };
            }
            newReportTypeIndex[type.main_category][type.descriptor].versions[type.version] = { //Add this version to the report type bucket
              id: type.id,
              order: type.order,
              just_for_ai: type.just_for_ai,
              disabled_for_new: type.disabled_for_new,
              needs_permission: type.needs_permission
            }; 
            //Set this type to be the newest version, if it is newer than the last processed newest version
            if (type.version >= newReportTypeIndex[type.main_category][type.descriptor].newest_version) {
              newReportTypeIndex[type.main_category][type.descriptor].newest_version = type.version;
            }
          }

          context.commit('setReportTypeIndex', newReportTypeIndex);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    fetchReportType (context, id) {
      return new Promise((resolve, reject) => {
        let url = `/entry-types/${id}`;
        cache.get(url)
        .then((reportType) => {
          if (reportType == null){
            throw 'Cache Miss'; //Go to the next catch
          }
          return reportType;
        })
        .then((reportType) => resolve(reportType)) //Has been found in cache, return it
        .catch(() => { //Not found in cache, request it
          api
          .get(url)
          .then(response => { //Use id as key in one object and Group ids by category in a separate object
            let newReportType = response.data;

            //If the old format (object, not array) is used, convert it to the new format
            if (newReportType.definition && !Array.isArray(newReportType.definition)){
              let newDefinition = [];

              for (const [name, category] of Object.entries(newReportType.definition)) {
                //Add name to category
                let newCategory = {
                  name,
                  ...category
                }
                newDefinition.push(newCategory);
              }

              newReportType.definition = newDefinition;
            }
            
            cache.set(url, newReportType); //Add this reportType to cache
            return resolve(newReportType);
          })
          .catch(error => {
            reject(error);
          });
        });
      });
    },
    fetchMainCategories (context) {
      return new Promise((resolve, reject) => {
        api
        .get('/main-categories')
        .then(response => { //Use id as key in one object
          let newMainCategories = {};
          for (let category of response.data){
            let newNameObject = {}; //Save all localized names in corresponding locale keys in the object instead of an array

            for (let localizedName of category.name) {
              if (localizedName.locale && localizedName.locale.locale && localizedName.text) {
                let locale = localizedName.locale.locale;
                
                newNameObject[locale] = localizedName.text;
              }
            }

            category.name = newNameObject;
            newMainCategories[category.id] = category;
          }
          context.commit('setMainCategories', newMainCategories);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addReportToQueue(context, {id, reportFormData, totalFileSize, reportDetails}){ //TODO Tests: Restart app with one in local memory to see the error state, let an upload retry multiple times, Upload on a day with existing reports and on an existing horse.
      let currentIndizes = Object.keys(context.getters['getReportUploadStatus']);
      let nextIndex = currentIndizes.length > 0 ? (Math.max(currentIndizes) + 1) : 0; //FIXME Was NaN once! Might have been a one-time bug during coding!
      nextIndex = String(nextIndex);
      
      context.commit('addReportUploadStatus', {
        index: nextIndex,
        status: {
          id,
          details: reportDetails,
          totalFileSize,
          progress: 0,
          stoppedRetry: false, //Set to true, if the upload fails because of an issue not related to connection, don't retry autoamtically
          dataLost: false //Set to true, if the data in the queue is missing on a retry
        }
      });

      let serializedData = serializeFormData(reportFormData);
    
      return uploadQueue.set(nextIndex, serializedData) //Try to set it in a persistent storage
      .then(() => {
        return { index: nextIndex, persistent: true };
      })
      .catch((error) => { //If that fails, just keep it in local memory for now
        console.error("Failed to save in persistent uploadQueue!", error)
        inMemoryUploadQueue[nextIndex] = serializedData;
        return { index: nextIndex, persistent: false };
      });
    },
    removeUploadFromQueue(context, index){
      delete inMemoryUploadQueue[index];
      uploadQueue.remove(index);
    },
    uploadReport(context, {id, reportFormData, totalFileSize, progressCallback}){
      let apiConfig = {};
      if (progressCallback) {
        apiConfig['onUploadProgress'] = progressEvent => progressCallback(progressEvent.loaded / progressEvent.total);
      }
      if (totalFileSize) { //If files should be uploaded, set higher timeout for this request only!
        apiConfig['timeout'] = calculateFileUploadTimeout(totalFileSize);
      }
      return new Promise((resolve, reject) => {
        let uploadPromise;
        if (id != null) { //On update id is set
          uploadPromise = api.put(`/reports/${id}`, reportFormData, apiConfig);
        } else {
          uploadPromise = api.post('/reports', reportFormData, apiConfig);
        }
        
        uploadPromise.then(response => { 
          //TODO Add this report to cache, if cache is implemented, keep files in mind. Result does not contain files. Add all the file blobs to the local fileCache.
          context.commit('addToReportIndex', response.data); //Add to index to show immediately, that it is saved
          if (id != null) { //On update id is set
            context.commit('removeReportIdFromIndex', id); //Remove the old one from the index, because it got updated and thus replaced
          }
          resolve(response.data);
        })
        .then(() => { //Update index, because something must have changed during the update //TODO Is it still needed?
          if (id != null) context.dispatch('fetchReportIndex');
        }) 
        .catch(error => {
          reject(error);
        });
      });
    },
    uploadReportAndUpdateStatus (context, {index, report}) {
      return new Promise((resolve, reject) => {
        context.commit('updateReportUploadProgress', { //Set the state that we are uploading now - Fake 1 to show progress
          index,
          progress: 1
        });

        context.dispatch('uploadReport', {
          ...report, 
          progressCallback: (progress) => {
            context.commit('updateReportUploadProgress', { //Update with the current upload state
              index,
              progress
            })
          }
        })
        .then((uploadResponse) => {
          context.dispatch('removeUploadFromQueue', index);
          context.commit('updateReportUploadProgress', { index, progress: 100 }); //Set as completed after upload
          if (uploadResponse != null && uploadResponse.id != null) context.commit('setReportUploadFinishedID', {index, id: uploadResponse.id});
          else context.commit('setReportUploadFinishedID', {index, id: -1});
          resolve(index);
        })
        .catch((error) => {
          context.commit('updateReportUploadProgress', { //Set the state that uploading did not succeed
            index,
            progress: -1
          })
          if (error) {
            if (error.response && error.response.status) {
              if (!NETWORK_ERRORS.includes(error.response.status)) { //Not a network related error, don't retry
                context.commit('stopReportUploadRetry', index);
              }
            } //Else it is Offline - no status set - keep retrying
          } //TODO Retry automatically again, if it fails a second time because of an offline error now instead of another error? Or just leave it manual?
          reject(error);
        });
      });
    },
    removeFinishedUploadStatuses (context) {
      for (let [index, status] of Object.entries(context.getters['getReportUploadStatus'])){
        if (status.finishedID != null) context.commit('removeReportUploadStatus', index);
      }
    },
    createNewReport (context, report) {
      return context.dispatch('addReportToQueue', report).then(({index, persistent}) => {
        context.dispatch('uploadReportAndUpdateStatus', {index, report}); //Result is not returned to immediately resolve after adding it to the queue
        return {index, persistent};
      });
    },
    updateReport (context, report) {
      return context.dispatch('createNewReport', report); //Implementation is the same, so reuse the function
    },
    loadReportFromUploadQueue (context, index) {
      return uploadQueue.get(index)
        .then((reportFormData) => {
          if (reportFormData == null){
            throw 'Data not in persistent storage'; //Go to the next catch
          }
          return reportFormData;
        })
        .catch(() => { //Catch everything related to not getting the value from persistent storage
          console.error(`Upload queue index ${index} could not be found in persistent storage`);
          let reportFormData = inMemoryUploadQueue[index]; //If not found, try to find it in memory
          if (reportFormData == null) { //If still not found, mark this report as lost data
            console.error(`Upload queue index ${index} could also not be found in memory`);
            throw 'Data not in memory';
          }
          return reportFormData; //Continue chain when report is found in memory
        });
    },
    retryUploads (context, manualRetry = false) { //Set a sync handler in the main app view and call this from that handler
      let uploadPromiseChain = Promise.resolve(); //Create a resolved promise as a start for the chain
      let failedUploads = {}; //Save the ids of failed uploads as feedback to calling function

      return new Promise((resolveUpload, rejectUpload) => {
        for (const [index, status] of Object.entries(context.getters['getReportUploadStatusUnfinished'])){
          if (status.progress === -1 && (!status.stoppedRetry || manualRetry) && !status.dataLost) { //If it is not currently uploading, not stopped because of an error unrelated to connection (if automatic) and data is not missing, retry.
            //Set all that can be uploaded to the uploading status to indicate retry
            context.commit('updateReportUploadProgress', {
              index,
              progress: 0
            })
            
            //Chain all uploads in a promise chain to wait for each other
            uploadPromiseChain = uploadPromiseChain.then(
              //Try to get from persistent storage
              () => context.dispatch('loadReportFromUploadQueue', index)
            )
            .catch(() => { //Value can't be found anywhere. Consider the data lost. //TODO Test that we can reach this catch and won't continue further with the next then
              context.commit('setReportUploadDataLost', index);
              throw 'Data lost';
            })
            .then((reportFormData) => {
              return context.dispatch('uploadReportAndUpdateStatus', {
                index,
                report: {
                  id: status.id,
                  reportFormData: unserializeFormData(reportFormData),
                  totalFileSize: status.totalFileSize
                }
              })
            })
            .catch((error) => {
              console.error(`Upload retry for index ${index} failed`, error);
              context.commit('updateReportUploadProgress', { //Set the state that uploading did not succeed - again to catch edge cases
                index,
                progress: -1
              })
              failedUploads[index] = error; //Save error for feedback
            }); //Continue with the next one, even if this upload fails
          } else {
            setTimeout(() => {
              context.commit('updateReportUploadProgress', { //Set the state that uploading did not succeed after timeout has passed. That way we catch the edge case where app was restarted during upload!
                index,
                progress: -1
              })
            }, process.env.VUE_APP_BACKEND_API_TIMEOUT * 10); //TODO Find a better way to check if upload is still ongoing
          }
        }
        uploadPromiseChain.finally(() => {
          if (Object.keys(failedUploads).length >= 1) { //If one failed, reject the whole upload
            rejectUpload(failedUploads);
          }
          resolveUpload(); //Only resolve when the last update has completed
        });
      });
    },
    setAllReportUploadsUnsuccesful (context) {
      for (let index of Object.keys(context.getters['getReportUploadStatusUnfinished'])){
        context.commit('updateReportUploadProgress', { //Set the state that uploading did not succeed
          index,
          progress: -1
        })
      }
    },
    processReport (context, reportToProcess) {
      //Shorthand async function call to get a promise and use await
      return (async () => {
        let report = reportToProcess;

        //Structure the field data in the category->subcategory->field format like in the report definition for better lookup
        let categories = {};

        //Store keys and fileData of all entries with files separately for a better lookup just for files
        let files = [];

        let fileToken = null;
        
        for (const component of report.fields) {
          if (component.key) {
            //Build object tree through the steps category, subcategory, etc.
            let categorySteps = component.key.split(CATEGORY_SEPARATOR);
            let previousStep = categories;
            for (const categoryStep of categorySteps) {
              if (!(categoryStep in previousStep)){
                previousStep[categoryStep] = { type: 'category' }; //Default type is category - gets overwritten below
              }
              previousStep = previousStep[categoryStep];
            }

            //Fill the last instance, aka the component itself
            if (component.__component){
              let componentType = component.__component.split('.');
              previousStep.type = componentType[componentType.length - 1];
            }

            //Preprocess files
            if (component.file) { //TODO Adapt to multiple files
              //Remove unsupported mime types
              if (component.file.mime != null && component.file.mime.includes('octet-stream')) component.file.mime = '';

              //If we get a blob provided, use it, otherwise get it from the URL
              if (component.file.blob) {
                component.file.blobURL = URL.createObjectURL(component.file.blob);
                delete component.file.blob;
              } else {
                const fileID = `${report.id}/${encodeURIComponent(component.key)}`;
                //Try to get file from cache
                component.file.blobURL = await fileCache.get(fileID)
                  .then((fileBlob) => {
                    if (fileBlob == null){
                      throw 'Cache Miss'; //Go to the next catch
                    }
                    return URL.createObjectURL(fileBlob);
                  })
                  .catch(async () => { //Not found in cache, request it
                    //If cache miss occurs, get the token for the report, return the file url and start caching the file
                    if (fileToken == null) fileToken = await context.dispatch('getReportFileToken', report.id).catch(() => null);
                    let fileAPIUrl = `${process.env.VUE_APP_BACKEND_API_URL}reports/file/${fileID}?token=${fileToken}`;

                    //Fetch in background and save it to cache
                    const cacheKey = fileID;
                    fetch(fileAPIUrl)
                      .then(fetchResult => fetchResult.blob())
                      .then(blobFile => fileCache.set(cacheKey, blobFile))
                      .catch((error) => {
                        console.error(error);
                      });
                    
                    //Return API url to get access to the file immediately
                    return fileAPIUrl;
                  });
              }
              //Generate thumbnail for video
              if (component.file.mime && component.file.blobURL && component.file.mime.includes('video')) {
                component.file.thumbnail = await getFirstFrameFromVideo(component.file.blobURL, component.file.mime); 
              }

              files.push({
                keys: categorySteps,
                fileObjectArray: [component.file]
              }); //Store a reference to the file object in a separate array
            }

            //Copy all attributes over
            Object.assign(previousStep, component);

            //Delete unnecessary attributes
            delete previousStep.key;
            delete previousStep.__component;
            delete previousStep.id;
          }
        }
        
        //Replace unnecessary fields with better structured object
        report.fields = categories;

        if (files.length > 0) {
          report.files =  files;
        }

        return report;
      })();
    },
    getReportFileToken (context, id) {
      return new Promise((resolve, reject) => {
        api
        .get(`/reports/token/${id}`)
        .then(response => {
          if (response.data){
            resolve(response.data);
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    fetchReport (context, id) {
      return new Promise((resolve, reject) => {
        let url = `/reports/${id}`;
        cache.get(url)
        .then((report) => {
          if (report == null){
            throw 'Cache Miss'; //Go to the next catch
          }
          return report;
        })
        .then(async (report) => resolve(await context.dispatch('processReport', report))) //Has been found in cache, process and return it
        .catch(() => { //Not found in cache, request it
          api
          .get(url)
          .then(async (response) => {
            let report = null;
            if (response.data) {
              report = response.data;
              await cache.set(url, report); //Add this report to cache in raw form for minimal data usage and to process the blobs on load later correctly

              //Process report and return it
              report = await context.dispatch('processReport', report);
            }
            return resolve(report);
          })
          .catch(error => {
            reject(error);
          });
        });
      });
    },
    fetchUploadingReport (context, index) {
      return new Promise((resolve, reject) => {
        context.dispatch('loadReportFromUploadQueue', index)
        .then((reportFormData) => {
          if (reportFormData == null){
            throw 'Not found'; //Go to the next catch
          }
          return reportFormData;
        })
        .then(async (reportFormData) => { //Has been found in uploadQueue, process and return it
          if (reportFormData != null && reportFormData.data != null) {
            let report = JSON.parse(reportFormData.data);
            let uploadStatus = context.getters['getReportUploadStatus'];
            let updatedReport;

            if (uploadStatus != null && uploadStatus[index] != null && uploadStatus[index].id != null) {
              updatedReport = await context.dispatch('fetchReport', uploadStatus[index].id);
            }
            //Set all the file references in the report
            for (let filePath of Object.keys(reportFormData).filter((key) => key != 'data')) {
              _.set(report, filePath.replace(/^(files.)/,''), 
                { 
                  blob: reportFormData[filePath],
                  mime: (reportFormData[filePath] != null) ? reportFormData[filePath].type : undefined
                }
              );
            }

            let processedUploadingReport = await context.dispatch('processReport', report);

            //If we found a report that this report updates, get all the original fields and overwrite them with the update to show the full report - updates only contain updated fields!
            if (updatedReport != null) {
              //Add files of the old report only if they don't exist in the new one
              if (updatedReport.files != null) {
                //Create an array for the files, if it doesn't exist yet
                if (processedUploadingReport.files == null) processedUploadingReport.files = [];
                for (let file of updatedReport.files) {
                  if (!(_.has(processedUploadingReport.fields, file.keys))) {
                    processedUploadingReport.files.push(file);
                  }
                }
              }
              
              _.mergeWith(updatedReport.fields, processedUploadingReport.fields, (destination, source) => {
                //If the field is not a category, overwrite it with the source fully! That way also deleted fields are applied correctly! And no unnecessary properties leak into the report from the old one!
                if (destination != null && source != null && destination.type != null && source.type != null && (destination.type != 'category' || source.type != 'category')) {
                  return source;
                }
              });
              processedUploadingReport.fields = updatedReport.fields;
            }

            resolve(processedUploadingReport);
          }
        })
        .catch((error) => {
          console.error(error);
          reject('Report is currently not accessible. Try opening again.');
        });
      });
    },
    analyseReport (context, id) {
      return new Promise((resolve, reject) => {
        api
        .get(`/reports/analyse/${id}`)
        .then(response => {
          if (response.data && response.data.analyses){
            resolve(response.data.analyses); //TODO Attach to cached report!
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    deleteReport (context, id) {
      return new Promise((resolve, reject) => {
        let url = `/reports/${id}`;
        api
        .delete(url)
        .then(response => {
          if (response.data){
            if (Array.isArray(response.data)){ //If an array of IDs is returned, remove them from cache
              for (let relatedId of response.data) {
                cache.remove(`/reports/${relatedId}`);
              }
              //Remove the deleted reports from the index as a new fetch of the index does not include the deleted ones
              context.commit('removeReportsFromIndexAndRelations', response.data); //TODO Also remove report from cache and all its files from the file cache
            }
            resolve(response.data);
          }
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    clearCaches () {
      return clearCacheStorage();
    },
    createNewReportType (context, newReportType) {
      return new Promise((resolve, reject) => {
        api
        .post('/entry-types', newReportType)
        .then(response => { 
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    hideReportType (context, id) {
      return new Promise((resolve, reject) => {
        api
        .put(`/entry-types/${id}`, { 'disabled_for_new': true })
        .then(() => resolve(true))
        .catch(error => {
          reject(error);
        });
      });
    },
    searchReports (context, searchTerm) {
      //Apply selected day filters for improved performance
      let minDay = context.getters.getSelectedDay;
      let maxDay = context.getters.getSelectedTimespanDay;

      let params = {
        '_s': searchTerm
      };

      if (minDay != null) {
        params['timestamp_gte'] = dayjs(minDay, 'DD.MM.YYYY').utc().toISOString();
      }

      if (maxDay != null) {
        params['timestamp_lte'] = dayjs(maxDay, 'DD.MM.YYYY').utc().toISOString();
      }

      return new Promise((resolve, reject) => {
        api
        .get('/reports/search', { params })
        .then(response => {
          if (response.data && Array.isArray(response.data)){
            //Convert array to indexed object
            let searchIndex = {};
            for (let searchResult of response.data) {
              if (searchResult.id != null) {
                searchIndex[searchResult.id] = searchResult.score || null;
              }
            }
            resolve(searchIndex);
          }
          resolve({});
        })
        .catch(error => {
          reject(error);
        });
      });
    }
  },
}