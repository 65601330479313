import MediaInfo from 'mediainfo.js';

import mediaInfoLibrary from 'mediainfo.js/dist/MediaInfoModule.wasm';

const ERROR_TIMEOUT = 1500;
// Note from: https://github.com/diachedelic/capacitor-blob-writer
// By choosing a chunk size which is a multiple of 3, we avoid a bug in
// Filesystem.appendFile, only on the web platform, which corrupts files by
// inserting Base64 padding characters within the file. See
// https://github.com/ionic-team/capacitor-plugins/issues/649.
const CHUNK_SIZE = 3 * 128 * 1024; // bytes

export function getFirstFrameFromVideo(blobURL, mime){
  return new Promise((resolve) => {
    let video = document.createElement('video');
    video.controls = true;
    video.playsInline = true;
    video.muted = true;
    video.crossOrigin = 'anonymous';

    video.onloadeddata = () => {
      video.currentTime = 0.1; //Seek to the beginning
    };
    video.onseeked = () => setTimeout(() => {
      let canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        if (blob != null) {
          resolve(URL.createObjectURL(blob));
        } else {
          resolve(null);
        }
      }, 'image/png');
    }, 0);
    video.onerror = () => {
      resolve(null);
    };

    //Timeout to catch other unforeseen incompatibilities
    setTimeout(() => resolve(null), ERROR_TIMEOUT);

    if (mime != null && mime.length > 0 && !video.canPlayType(mime)){
      resolve(null);
    }

    let source = document.createElement('source');

    source.src = blobURL;
    if (mime != null && mime.length > 0) source.type = mime;

    video.appendChild(source);
    video.load();
  });
}

export function splitDataURL(dataUrl) {
  let components = dataUrl.split(',');
  let mimeAndFormat = components[0].match(/:(.*?);(.*?)/);
  return {mime: mimeAndFormat[1], format: mimeAndFormat[2], data: components[1]};
}

/* Creates a base64 encoded version of the blob and calls callback with it */
export function blobToBase64(blob, callback) {
  var reader = new FileReader();
  reader.onload = function() {
      var dataUrl = reader.result;
      var base64 = dataUrl;
      callback(base64);
  };
  reader.readAsDataURL(blob);
}

/**
 * 
 * Based on https://github.com/diachedelic/capacitor-blob-writer
 * 
 */
export function arrayBufferToBase64(buffer) {
  const bytes = new Uint8Array(buffer);
  let binaryString = '';
  for (let byteIndex = 0; byteIndex < bytes.byteLength; byteIndex++) {
    binaryString += String.fromCharCode(bytes[byteIndex]);
  }
  return window.btoa(binaryString);
}

/**
 * 
 * Based on https://github.com/diachedelic/capacitor-blob-writer
 * 
 * Recursive function. Promise is resolved, once all data has been processed.
 * Reads the blob in chunks and returns base64 data in the callback.
 * Callback can return promise that this function will wait for before the next chunk is processed.
 */
export function blobToBase64Chunks(blob, callback) {
  if (blob.size === 0) {
      return Promise.resolve();
  }

  const chunkBlob = blob.slice(0, CHUNK_SIZE);

  // Read the Blob as an ArrayBuffer
  return new window.Response(chunkBlob).arrayBuffer().then(
    function readBufferChunk(buffer) {
      return callback(arrayBufferToBase64(buffer)) //Callback can be a promise
    }
  ).then(function writeRemaining() {
      return blobToBase64Chunks(blob.slice(CHUNK_SIZE), callback);
  });
}

export function rawBinaryToBlob(byteCharacters, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function base64ToBlob(base64Data, contentType) {
  var byteCharacters = atob(base64Data);
  return rawBinaryToBlob(byteCharacters, contentType);
}

export function getVideoMetadata(videoFile){
  if (videoFile == null) {
    return new Promise((resolve, reject) => reject('No video file provided!'));
  }
  const getSize = () => videoFile.size;
  const readChunk = (chunkSize, offset) =>
    new Promise((resolve, reject) => {
      let blob = videoFile.slice(offset, offset + chunkSize);
      if (blob == null) reject();
      new window.Response(blob).arrayBuffer()
        .catch((error) => reject(error))
        .then((result) => resolve(new Uint8Array(result)));
    }
  );

    return new Promise((resolve, reject) => {
      MediaInfo({locateFile: () => mediaInfoLibrary}).then((mediainfo) => {
        mediainfo
          .analyzeData(getSize, readChunk)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          })
      });
    });      
}

export function getFrameInfoFromMetadata(metadata){
  if (metadata != null && metadata.media != null && Array.isArray(metadata.media.track)){
    for (let track of metadata.media.track){
      if (track.FrameRate != null && track.FrameCount != null) {
        return {
          FrameRate: track.FrameRate,
          FrameCount: track.FrameCount
        };
      }
    }
  }
  return null;
}