<template id="filter-popup">
  <ion-content>
    <ion-header>
      <ion-item v-if="hideFilterTitle">
        <ion-label class="standalone-title" v-if="availableFilters != null && currentSelectedFilter != null && currentSelectedFilter in availableFilters">
          <b>{{ availableFilters[currentSelectedFilter].localizedName || i18n.$t('default_interaction.select') }}</b>
        </ion-label>
      </ion-item>
      <ion-item v-else>
        <ion-label position="stacked">{{ i18n.$t('filter.filter_by') }}</ion-label>
        <ion-select v-model="currentSelectedFilter" :placeholder="i18n.$t('default_interaction.select')">
          <ion-select-option v-for="availableFilter in Object.keys(availableFilters).sort()" :key="availableFilter" :value="availableFilter">
            {{ availableFilters[availableFilter].localizedName }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-searchbar v-if="currentSelectedFilterOptions != null && currentSelectedFilterOptions.options != null && Object.keys(currentSelectedFilterOptions.options).length" :placeholder="i18n.$t('default_interaction.search')" @keyup="blurOnEnter" @ionInput="searchFilter = $event.target.value"></ion-searchbar>
    </ion-header>
    <ion-list v-if="currentSelectedFilterOptions != null && currentSelectedFilterOptions.options != null && Object.keys(currentSelectedFilterOptions.options).length" class="filter-container">
      <ion-radio-group v-if="currentFilteredFilterOptions != null && Object.keys(currentFilteredFilterOptions).length" allow-empty-selection :value="currentValue" @ionChange="currentSelectedFilterOptions.allowMultiple ? undefined : setValue($event.target.value)">
        <ion-item-group v-for="category in Object.keys(currentFilteredFilterOptions).sort(createCategorySortFunction(currentFilteredFilterOptions))" :key="category">
          <ion-item-divider v-if="category != 'null'">
            <ion-label>{{ category }}</ion-label>
          </ion-item-divider>
          <ion-item v-for="(option) in currentFilteredFilterOptions[category].sort(optionSortFunction)" :key="option.value"> <!-- TODO Apadt to allow additional order property to be set in category and each option and sort accordingly -->
            <ion-checkbox v-if="currentSelectedFilterOptions.allowMultiple" slot="start" :checked="isValueSet(option.value)" @ionChange="$event.target.checked ? setValue(option.value) : removeValue(option.value)"></ion-checkbox>
            <ion-radio v-else slot="start" :value="option.value" :checked="isValueSet(option.value)" @ionSelect="setValue(option.value)"></ion-radio>
            <ion-label :class="['filter-option-label', (option.italic) ? 'italic' : '', (option.bold) ? 'bold' : '']" :color="(option.color) ? option.color : undefined">
              {{ option.localizedName }}
            </ion-label>
          </ion-item>
        </ion-item-group>
      </ion-radio-group>
      <ion-item v-else lines='none'>
        <ion-label class="italic">{{ i18n.$t('filter.no_entries.' + ((isSelect) ? 'select' : 'filter')) }}</ion-label>
      </ion-item>
    </ion-list>
    <ion-footer>
      <ion-button expand="full" :disabled="!filterComplete" @click="applyFilters()">{{ i18n.$t('default_interaction.' + ((isSelect) ? 'select' : 'apply')) }}</ion-button>
    </ion-footer>
  </ion-content>
</template>

<script>

import { IonContent, IonItem, IonLabel, IonSelect, IonSelectOption, IonList, IonItemGroup, IonItemDivider, IonButton, IonCheckbox, IonRadioGroup, IonRadio, IonSearchbar, IonHeader, IonFooter, popoverController } from '@ionic/vue';
import { computed, defineComponent, ref, watch } from 'vue';

import { useI18n } from "@/utils/i18n";

import _ from 'lodash';

const FilterPopup = defineComponent({
  name: 'FilterPopup',
  components: { IonContent, IonItem, IonLabel, IonSelect, IonSelectOption, IonList, IonItemGroup, IonItemDivider, IonButton, IonCheckbox, IonRadioGroup, IonRadio, IonSearchbar, IonHeader, IonFooter },
  props: {
    'filter': String,
    'value': [String, Number, Array, Boolean], 
    'availableFilters': {
      type: Object,
      default: () => {return {}}
    },
    'hideFilterTitle': Boolean,
    'isSelect': Boolean
  },
  setup(props) {
    const i18n = useI18n();

    const currentSelectedFilter = ref(_.cloneDeep(props.filter));

    const currentValue = ref(_.cloneDeep(props.value));

    const searchFilter = ref(null);

    watch(currentSelectedFilter, (newFilter, oldFilter) => {
      if (oldFilter != newFilter) currentValue.value = null;
    });

    const setValue = function(selectedOption) {
      if (selectedOption != null && selectedOption.length == 0) selectedOption = null;
      if (currentSelectedFilterOptions.value.allowMultiple) {
        if (!Array.isArray(currentValue.value)) currentValue.value = [];
        currentValue.value.push(selectedOption);
      } else {
        currentValue.value = selectedOption;
      }
    }

    const removeValue = function(selectedOption) {
      if (Array.isArray(currentValue.value)) _.pullAllWith(currentValue.value, [selectedOption], _.isEqual);
      else currentValue.value = null;
    };

    const isValueSet = function(option) {
      if (Array.isArray(currentValue.value)) return _.some(currentValue.value, (value) => _.isEqual(value, option));
      else return _.isEqual(currentValue.value, option);
    }

    const isPresetValue = function(option) {
      if (Array.isArray(props.value)) return _.some(props.value, (value) => _.isEqual(value, option));
      else return _.isEqual(props.value, option);
    }

    const currentSelectedFilterOptions = computed(() => {
      if (currentSelectedFilter.value != null && currentSelectedFilter.value in props.availableFilters) {
        return props.availableFilters[currentSelectedFilter.value];
      }
      return {}
    });

    const currentFilteredFilterOptions = computed(() => {
      if (currentSelectedFilterOptions.value.options != null) {
        let mappedCategories = _.mapValues(currentSelectedFilterOptions.value.options, (categoryOptions) => {
          let filteredCategory = _.filter(categoryOptions, (option) => {
            if (option.unfiltered || isPresetValue(option.value) || isValueSet(option.value)) return true; //Always include manually set options that should be visible all the time or already selected options
            
            if (option.localizedName != null && searchFilter.value != null) 
              return option.localizedName.toLowerCase().includes(searchFilter.value.toLowerCase()); //If a searchTerm is present, include it if it is found
            return true;//No search term entered, include this option
          });

          if (filteredCategory.length) {
            return filteredCategory;
          } else {
            return undefined;
          }
        });
        return _.pickBy(mappedCategories, (categoryOptions) => categoryOptions != null); //Filter out empty categories
      } else {
        return {};
      }
    });

    const createCategorySortFunction = computed(() => function(/* object */) {
      return function(firstKey, secondKey) {
        if (firstKey == secondKey) return 0;
        if (firstKey == 'null') return -1;
        if (secondKey == 'null') return 1;
        /*if (object != null && object[firstKey] != null && object[secondKey] != null && object[firstKey].order != null && object[secondKey].order != null) {
          if (object[firstKey].order < object[secondKey].order) return -1;
          if (object[firstKey].order > object[secondKey].order) return 1;
        }*/
        return firstKey.localeCompare(secondKey, i18n.locale.value);
      }
    });

    const optionSortFunction = computed(() => {
      return function(firstOption, secondOption) {
        if (firstOption != null && secondOption != null) {
          /*if (firstOption.order != null && secondOption.order != null) {
            if (firstOption.order < secondOption.order) return -1;
            if (firstOption.order > secondOption.order) return 1;
          }*/
          if (firstOption.localizedName != null && secondOption.localizedName != null) {
            return firstOption.localizedName.localeCompare(secondOption.localizedName, i18n.locale.value);
          }
        }
        return 0;
      }
    });

    const filterComplete = computed(() => {
      if (currentSelectedFilter.value == null || currentValue.value == null) {
        return false;
      }
      if (Array.isArray(currentValue.value) || typeof currentValue.value === 'string' || currentValue.value instanceof String) { //If we have an array or string check that it is not empty
        return currentValue.value.length > 0;
      }
      if (currentValue.value === true || currentValue.value === false) { //If we have a boolean, return the value
        return true;
      }
      return !isNaN(currentValue.value); //Last check can only be a number. Can not be NaN
    });

    const applyFilters = function(){
        popoverController.dismiss({ filter: currentSelectedFilter.value, value: currentValue.value });
    }

    const blurOnEnter = function(event){
      //If enter key was pressed, blur
      if(event.key === 'Enter') {
        event.preventDefault();
        event.target.blur();
      }
    }

    return { i18n, applyFilters, setValue, removeValue, isValueSet, searchFilter, currentSelectedFilter, currentSelectedFilterOptions, currentFilteredFilterOptions, currentValue, createCategorySortFunction, optionSortFunction, filterComplete, blurOnEnter }
  }
});

export async function openFilterPopup(component, clickEvent, availableFilters, filter, value, options = {}){
  if (availableFilters != null && Object.keys(availableFilters).length) {
    const popup = await popoverController
      .create({
        component,
        event: clickEvent,
        cssClass: 'filter-popover',
        componentProps: {
          filter,
          value,
          availableFilters,
          ...options
        },
      })
    popup.present();
    return popup.onDidDismiss();
  }
}

export const openFilterPopupAsSelectInterface = function(component, event) {
  event.preventDefault();
  event.stopImmediatePropagation();

  let options = _.map(event.target.getElementsByTagName('ion-select-option'), (option) => { 
    return { 
      value: option.value,
      localizedName: option.textContent,
      italic: (option.hasAttribute('italic')) ? true : undefined,
      bold: (option.hasAttribute('bold')) ? true : undefined,
      color: (option.hasAttribute('color')) ? option.getAttribute('color') : undefined,
      unfiltered: (option.hasAttribute('unfiltered')) ? true : undefined
    }
  });

  let label = event.target.getAttribute('aria-label');
  if (label != null) {
    //Remove the placeholder if present and use it as a label
    let splitPosition = label.indexOf(', ');
    label = label.substring(splitPosition + 1);
  }
  
  openFilterPopup(component, event, 
    { 
      'select': { 
        localizedName: label, 
        options: { null: options } 
      }
    },
    'select',
    event.target.value,
    {
      hideFilterTitle: true,
      isSelect: true
    })
  .then((data) => {
    if (data != null && data.data != null && data.data['value'] != null) {
      event.target.value = data.data['value'];
    }
  })
}

export default FilterPopup;
</script>

<style>
.standalone-title b {
  font-size: 1.5em;
}

.filter-popover {
  --max-width: min(80vw, 350px);
  --width: min(80vw, 350px);
  --max-height: 50vh;
}

.filter-popover .popover-viewport {
  max-height: 50vh;
  overflow: hidden;
}
</style>

<style scoped>
ion-list, ion-item {
  --background: var(--ion-background-color);
  background: var(--ion-background-color);
}

/* Allow for longer texts in select and indent every line but the first using a negative offset on the first line in a wrapped text */
.filter-option-label {
  padding-left: 12px;
  white-space: normal!important;
  text-indent: -6px;
  hyphens: auto;
}

ion-content::part(scroll) {
  max-height: 50vh;
  overflow-y: scroll;
  padding: 16px;
  padding-bottom: 0px;
}

ion-checkbox, ion-radio {
  margin-right: 16px;
  margin-left: 0px;
}

ion-footer {
  background: var(--ion-background-color, #fff);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  padding-bottom: 16px;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}
</style>
