export function isReportTypeVisible(reportTypeVersion){
    if (reportTypeVersion == null) {
        return false;
    }
    if (reportTypeVersion.just_for_ai) {
        return false;
    }
    if (reportTypeVersion.disabled_for_new) {
        return false;
    }
    return true;
}

export function isReportTypeVisibleForExistingReports(reportTypeVersion){
    if (reportTypeVersion == null) {
        return false;
    }
    if (reportTypeVersion.just_for_ai) {
        return false;
    }
    return true;
}