<template>
  <ion-page id="custom-page">
    <ion-header>
      <MainToolbar :isSimple="simpleToolbar" :title="i18n.$t('tracking.title')" />
    </ion-header>
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="refreshReportIndex($event.target)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-searchbar :disabled="isOffline" :placeholder="i18n.$t(isOffline ? 'tracking.search_offline' : 'default_interaction.search')" @keyup="blurOnEnter" @ionBlur="activeSearch = $event.target.value" @ionClear="activeSearch = null"></ion-searchbar>
      <div class="filter-container">
        <div class="chip-container">
            <ion-chip color="primary" outline :class="isSelectingDate ? 'date-chip active' : 'date-chip'" @click="toggleSelectDate()">
              <ion-icon :icon="calendarNumber"></ion-icon>
              <ion-label v-if="selectedDay != null">{{ dayToLocalFormatShort(selectedDay) }}</ion-label>
              <ion-label v-else>{{ i18n.$t('tracking.no-day-selected-hint-short') }}</ion-label>
            </ion-chip>
            <ion-chip :color="(selectedTimespanDay != null) ? 'primary' : 'medium'" outline :class="isSelectingTimespanDate ? 'date-chip active' : 'date-chip'">
              <ion-icon :icon="calendar" @click="toggleSelectEndDate()" class="chip-icon"></ion-icon>
              <ion-label v-if="selectedTimespanDay != null" @click="toggleSelectEndDate()">{{ dayToLocalFormatShort(selectedTimespanDay) }}</ion-label>
              <ion-label v-else @click="toggleSelectEndDate()"><b>+</b> {{ i18n.$t('tracking.to-day-hint') }}</ion-label>
              <ion-icon v-if="selectedTimespanDay != null" :icon="closeCircle" @click="selectedTimespanDay = null" class="chip-close"></ion-icon>
            </ion-chip>
            <ion-chip v-for="filterType in Object.keys(activeFilters).sort()" :key="filterType" color="tertiary" outline>
              <ion-icon :icon="getFilterIcon(filterType)" @click="editFilter($event, filterType)" class="chip-icon"></ion-icon>
              <ion-label @click="editFilter($event, filterType)">
                <span v-if="availableFilters[filterType] != null" class="chip-filter-name">{{availableFilters[filterType].localizedName }}: </span>
                <span v-if="activeFilters != null && Array.isArray(activeFilters[filterType]) && activeFilters[filterType].length > 1">{{ activeFilters[filterType].length }} {{ i18n.$t('tracking.selected') }}</span>
                <span v-else-if="activeFilters != null && Array.isArray(activeFilters[filterType]) && activeFilters[filterType].length == 1">{{ getLocalizedFilterName(filterType, activeFilters[filterType][0]) }}</span>
                <span v-else>{{ getLocalizedFilterName(filterType, activeFilters[filterType]) }}</span>
              </ion-label>
              <ion-icon :icon="closeCircle" @click="removeFilter(filterType)" class="chip-close"></ion-icon>
            </ion-chip>
        </div>
        <ion-button :disabled="!Object.keys(availableUnusedFilters).length" @click="editFilter($event)" shape="round" fill="solid" color="primary" class="filter-edit-button">
          <ion-icon slot="icon-only" :icon="filter"></ion-icon>
          <b>+</b>
        </ion-button>
      </div>
      <Calendar :class="(isSelectingDate || isSelectingTimespanDate) ? 'calendar' : 'calendar hidden'" v-model:selectedDay="currentlySelectingDay" v-model:selectedMonth="selectedMonth" :loadedDates="combinedReports" :minDate="isSelectingTimespanDate ? selectedDay : undefined" />
      
      <ion-item v-if="selectedDay == null" lines="none" id="selected-date">
        <ion-label>{{ i18n.$t('tracking.no-day-selected-hint') }}</ion-label>
      </ion-item>
      
      <ion-item lines="none" id="no-entries-hint">
        <ion-label v-if="combinedFilteredReportCount <= 0">{{ i18n.$t('tracking.no-entries-hint') }}</ion-label>
        <ion-label v-else :key="combinedFilteredReportCount">{{ i18n.$t('tracking.found-entries') }}: {{ combinedFilteredReportCount }}</ion-label>
      </ion-item>
      <div v-for="currentMonth in Object.keys(combinedFilteredReports).sort(sortDate)" :key="currentMonth">
        <hr>
        <ion-item lines="none" class="current-month">
          <ion-label>{{ monthToLocalFormat(currentMonth) }}</ion-label>
        </ion-item>
        <div v-for="currentDay in Object.keys(combinedFilteredReports[currentMonth].days).sort(sortDate)" :key="currentDay">
          <ion-item lines="none" class="current-date">
            <ion-label>{{ dayToLocalFormat(currentDay) }}</ion-label>
          </ion-item>
          <ion-card v-for="(horseEntries, horse) in combinedFilteredReports[currentMonth].days[currentDay].horses" :key="horse">
            <ion-card-header>
              <ion-card-title class="horse-name-title">{{ (horse != 'null') ? getHorseName(horse) : i18n.$t('tracking.horse_not_assigned') }}</ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <ion-list>
                <ion-item v-for="entry in Object.values(horseEntries).sort(sortByTimestamp)" :key="((entry.status != null) ? `U${entry.id}` : entry.id)"
                  class="report-entry-item"
                  detail
                  button
                  @click="navigateToReport(((entry.status != null) ? `U${entry.id}` : entry.id))">
                  <ion-label>
                    <h2>{{ getLocalizedMainCategory(entry.type) }}</h2>
                    <h3>{{ timestampToDateTime(entry.timestamp) }}</h3>
                    <p class="wrap">{{ getLocalizedReportTypeName(entry.type) }}</p> 
                  </ion-label>
                  <ion-button 
                    v-if="entry.status != null"
                    :key="getUploadStatusDesign(entry.status).identifier"
                    shape="round"
                    fill="clear"
                    class="upload-progress-button"
                    :color="getUploadStatusDesign(entry.status).shortColor"
                    @click.stop="showUploadStatus()"> <!--TODO Maybe add green pencil icon to reports that are being updated, also in upload overview, and also maybe on already updated ones -->
                    <CircularProgress
                      class="upload-progress"
                      :style="'--color: var(' + getUploadStatusDesign(entry.status).color + ');'"
                      :progress="(entry.status.progress >= 0) ? entry.status.progress : 0">
                      <ion-icon :icon="getUploadStatusDesign(entry.status).icon"></ion-icon>
                    </CircularProgress>
                  </ion-button>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonRefresher, IonRefresherContent, IonIcon, IonButton, IonSearchbar, IonChip } from '@ionic/vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


import { calendarNumber, calendar, closeCircle, search, filter, location, list, bookmark } from 'ionicons/icons';

import MainToolbar from '@/components/MainToolbar.vue';
import Calendar from '@/components/Calendar.vue';
import CircularProgress from '@/components/CircularProgress.vue';

import { useI18n } from "@/utils/i18n";

import { apiErrorToast } from '@/utils/error';

import { useDayjs } from '@/utils/dayjs';

import { isReportTypeVisibleForExistingReports } from '@/utils/report';

import { openUploadStatusModal, default as uploadModalComponent } from '@/components/UploadStatusModal.vue';
import { openFilterPopup, default as filterPopupComponent } from '@/components/FilterPopup.vue';

import _ from 'lodash';

export default  {
  name: 'Tracking',
  components: { IonHeader, IonContent, IonPage, MainToolbar, Calendar, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonRefresher, IonRefresherContent, CircularProgress, IonIcon, IonButton, IonSearchbar, IonChip },
  props: {
    simpleToolbar: Boolean
  },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const router = useRouter();

    const { dayjs, dayjsLocale, isReady, timezone } = useDayjs();

    const isSelectingDate = ref(false);

    const isSelectingTimespanDate = ref(false);

    const isOffline = computed(() => {
      return !(store.getters.isOnline);
    });

    const setSelectedMonthFromDay = function(day){
      if (day != null) {
        let currentDay = dayjs.utc(day, 'DD.MM.YYYY');
        if (currentDay != null && currentDay.isValid()) {
          selectedMonth.value = currentDay.format('MM.YYYY');
          return true;
        }
      }
      return false;
    }

    const toggleSelectDate = function(){
      //If we make it visible, set current month to the month of the current day
      if (!isSelectingDate.value) setSelectedMonthFromDay(selectedDay.value);
      isSelectingDate.value = !isSelectingDate.value;
      isSelectingTimespanDate.value = false;
    }

    const toggleSelectEndDate = function(){
      //If we make it visible, set current month to the month of the current day
      if (!isSelectingTimespanDate.value) setSelectedMonthFromDay(selectedTimespanDay.value);
      isSelectingTimespanDate.value = !isSelectingTimespanDate.value;
      isSelectingDate.value = false;
    }

    const FILTER_ICONS = { //TODO Add icon for horse and control_examination
      category: bookmark,
      type: list,
      location: location
    }

    const getFilterIcon = function(filterType) {
      if (filterType in FILTER_ICONS) {
        return FILTER_ICONS[filterType];
      }
      return filter;
    }

    const activeSearch = ref(null);

    const searchedReports = ref(null);

    watch(activeSearch, (newSearch) => {
      if (newSearch != null && newSearch.length) {
        //TODO Maybe also search reports in upload cache locally and add their tempId U+index. For now all of them are included in the applyFilter function, because there are not a lot.
        store.dispatch('reports/searchReports', newSearch)
        .then((searchedReportIndex) => {
          searchedReports.value = searchedReportIndex;
        })
        .catch((error) => {
          apiErrorToast(i18n, error);
          searchedReports.value = null;
        })
      } else { //No valid search term, reset filter
        searchedReports.value = null;
      }
    });

    const editFilter = function(event, filterType) {
      let value = null;
      if (activeFilters.value != null && activeFilters.value[filterType] != null) {
        value = activeFilters.value[filterType];
      }
      openFilterPopup(filterPopupComponent, event, _.assign({}, availableUnusedFilters.value, _.pick(availableFilters.value, filterType)), filterType, value)
        .then((data) => {
          if (data != null && data.data != null && data.data['filter'] != null && data.data['value'] != null) {
            activeFilters.value[data.data['filter']] = data.data['value'];
          }
        })
    }

    const removeFilter = function(filterType) {
      if (filterType in activeFilters.value) 
        delete activeFilters.value[filterType];
    }

    const getLocalizedFilterName = computed(() => {
      return (filterType, filterValue) => {
        if (availableFilters.value != null && filterType in availableFilters.value && availableFilters.value[filterType].options != null) {
          for (let category of Object.values(availableFilters.value[filterType].options)) {
            for (let option of category) {
              if (_.isEqual(option.value, filterValue)) return option.localizedName;
            }
          }
        }
        else return '';
      }
    });

    //TODO Don't load all reports when date is undefined? Or load all? Check behaviuor when no date or date range is selected
    //TODO Maybe add filter later to include only reports with media (Needs search from API again!)

    //Apply a filter for a single report - id can be null on currently uploading reports
    const applyReportFilters = computed(() => {
      return function(id, report) {
        //When a search was performed, check if this report is included
        if (searchedReports.value != null) {
          if (id != null && !(id in searchedReports.value)) return false;
        }

        //Check all filters - if one is not matched, return false
        for (let [attribute, filter] of Object.entries(activeFilters.value)) {
          if (report != null && filter != null) {
            let value;

            //Handle special cases
            switch (attribute) {
              case 'category':
                if (report.type != null) {
                  let reportType = store.getters['reports/getReportTypeById'](report.type);
                  if (reportType != null) {
                    value = reportType.main_category;
                  }
                }
                break;
            
              default:
                value = report[attribute];
                break;
            }

            //Convert to string if null to handle this special case
            if (value === null) value = 'null';

            if (Array.isArray(filter)) {
              if (!(_.flattenDeep(filter).includes(value))) return false;
            } else {
              if (value != filter) return false;
            }
          }
        }
        return true;
      }
    });

    const sortDate = function(firstDate, secondDate) {
      let firstValue = firstDate.split('.').reverse().join();
      let secondValue = secondDate.split('.').reverse().join();
      if (firstValue < secondValue) return -1;
      if (firstValue > secondValue) return 1;
      return 0;
    }

    const sortByTimestamp = function(firstObject, secondObject) {
      if (firstObject.timestamp != null && secondObject.timestamp != null) {
        if (firstObject.timestamp < secondObject.timestamp) return -1;
        if (firstObject.timestamp > secondObject.timestamp) return 1;
      }
      return 0;
    }

    const selectedDay = computed({
      get: () => store.getters['reports/getSelectedDay'],
      set: newValue => {
        store.dispatch('reports/updateSelectedDay', newValue);
      }
    });

    const selectedTimespanDay = computed({
      get: () => store.getters['reports/getSelectedTimespanDay'],
      set: newValue => {
        store.dispatch('reports/updateSelectedTimespanDay', newValue);
      }
    });

    const timestampToDateTime = computed(() => {
      return (timestamp) => {
        if (isReady.value) {
          return dayjs.utc(timestamp).locale(dayjsLocale.value).tz(timezone).format('LTS');
        } else {
          return dayjs.utc(timestamp).tz(timezone).format('LTS');
        }
      }
    });

    const timestampToDateObject = computed(() => {
      return (timestamp) => {
        if (isReady.value) {
          return dayjs.utc(timestamp).locale(dayjsLocale.value).tz(timezone);
        } else {
          return dayjs.utc(timestamp).tz(timezone);
        }
      }
    });

    const getLocalizedMainCategory = function(reportTypeId){
      let categoryNames = store.getters['reports/getMainCategoryNamesOfReportTypeId'](reportTypeId);
      if (categoryNames) {
        return categoryNames[i18n.locale.value];
      } else {
        return null;
      }
    }

    const getLocalizedMainCategoryById = function(mainCategoryId){
      let categoryNames = store.getters['reports/getMainCategoryNamesById'](mainCategoryId);
      if (categoryNames) {
        return categoryNames[i18n.locale.value];
      } else {
        return null;
      }
    }

    const getLocalizedReportTypeName = function(reportTypeId){
      let reportType = store.getters['reports/getReportTypeById'](reportTypeId);
      if (reportType && reportType.descriptor) {
        return reportType.descriptor; //TODO Use the translated name here
      } else {
        return null;
      }
    }

    const getLocalizedReportTypeNameByDescriptor = function(descriptor){
      if (descriptor) {
        return descriptor; //TODO Use the translated name here
      } else {
        return null;
      }
    }

    const getHorseName = function(horseId){
      let horse = store.getters['horses/getHorseById'](horseId);
      if (horse && horse.personal_horse_info) {
        return horse.personal_horse_info.name;
      } else {
        return null;
      }
    }

    //TODO Move as many of the filtered reports to the store for efficiency!!!!!

    const filteredReportIndexAndMetadata = computed(() => { //TODO Only includes new ones not the old, updated ones, is that by design? Probably for the best. Keep in mind when searching in the API.
      let newIndex = {};
      let usedReportTypeIDs = {};
      let index = store.getters['reports/getReportIndex'];

      if (selectedDay.value != null){
        let selectedDayObject = dayjs.utc(selectedDay.value, 'DD.MM.YYYY');
        let selectedTimespanDayObject = (selectedTimespanDay.value != null) ? dayjs.utc(selectedTimespanDay.value, 'DD.MM.YYYY') : null;

        //Get a list of all reports being currently updated by an upload
        let reportIdsBeingUpdated = store.getters['reports/getReportsBeingUpdated'];

        for (let month of Object.values(index)) {
          if (month != null && month.days != null) {
            for (let horses of Object.values(month.days)) {
              if (horses != null && horses.horses != null) {
                for (let [horse, reports] of Object.entries(horses.horses)){
                  for (let [id, report] of Object.entries(reports)){
                    //Use the local report date to sort it in a new localtime index
                    let localReportDate = timestampToDateObject.value(report.timestamp);
                    let localReportDayString = localReportDate.format('DD.MM.YYYY');
                    let localReportMonthString = localReportDate.format('MM.YYYY');
                    let reportType = store.getters['reports/getReportTypeById'](report.type);

                    if ((selectedTimespanDayObject == null && localReportDate.isSame(selectedDayObject, 'day')) || /* Only show the report when it is on the selected day */
                        (selectedTimespanDayObject != null && localReportDate.isBetween(selectedDayObject, selectedTimespanDayObject, 'day', '[]'))) { /* Or if a range is specified, in the filtered range */
                          
                      //Save this reports metadata
                      if (reportType != null) {
                        if (!(reportType.main_category in usedReportTypeIDs)) usedReportTypeIDs[reportType.main_category] = {};
                        if (!(reportType.descriptor in usedReportTypeIDs[reportType.main_category])) usedReportTypeIDs[reportType.main_category][reportType.descriptor] = [];
                        if (!(usedReportTypeIDs[reportType.main_category][reportType.descriptor].includes(report.type))) usedReportTypeIDs[reportType.main_category][reportType.descriptor].push(report.type);
                      }

                      //Applying filters
                      if (!(reportIdsBeingUpdated.includes(id)) && /* Hide reports that are currently being updated */
                          reportType && isReportTypeVisibleForExistingReports(reportType.type) && 
                          applyReportFilters.value(id, report, reportType)){

                        //Add to new index, piece by piece if not yet existing
                        if (!(localReportMonthString in newIndex)) {
                          newIndex[localReportMonthString] = { days: {} };
                        }
                        if (!(localReportDayString in newIndex[localReportMonthString].days)) {
                          newIndex[localReportMonthString].days[localReportDayString] = {
                            count: 0,
                            horses: {}
                          };
                        }
                        if (!(horse in newIndex[localReportMonthString].days[localReportDayString].horses)) {
                          newIndex[localReportMonthString].days[localReportDayString].horses[horse] = {};
                        }

                        newIndex[localReportMonthString].days[localReportDayString].horses[horse][id] = report;
                        newIndex[localReportMonthString].days[localReportDayString].count++;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return { index: newIndex, reportTypes: usedReportTypeIDs};
    });

    const calendarMonthReportIndex = computed(() => { //FIXME Not populated yet, when reports are freshly loaded and were not loaded before
      let newIndex = {};
      let index = store.getters['reports/getReportIndex'];

      if (selectedMonth.value != null){
        let selectedMonthObject = dayjs.utc(selectedMonth.value.month, 'MM.YYYY');
        
        let currentIndex = {};
        if (selectedMonth.value.month in index)
          currentIndex = _.cloneDeep(index[selectedMonth.value.month].days);

        //Check the borders of the month because in localtime it might be different by making them flow into another category
        let nextMonth = selectedMonthObject.add(1, 'month').startOf('month').format('MM.YYYY');
        let nextMonthFirstDay = selectedMonthObject.add(1, 'month').startOf('month').format('DD.MM.YYYY');
        let previousMonth = selectedMonthObject.subtract(1, 'month').endOf('month').format('MM.YYYY');
        let previousMonthLastDay = selectedMonthObject.subtract(1, 'month').endOf('month').format('DD.MM.YYYY');

        if (nextMonth in index)
          currentIndex[nextMonthFirstDay] = _.cloneDeep(index[nextMonth].days[nextMonthFirstDay]);
        if (previousMonth in index)
          currentIndex[previousMonthLastDay] = _.cloneDeep(index[previousMonth].days[previousMonthLastDay]);

        //Get a list of all reports being currently updated by an upload
        let reportIdsBeingUpdated = store.getters['reports/getReportsBeingUpdated'];

        for (let horses of Object.values(currentIndex)) {
          if (horses != null) {
            for (let [horse, reports] of Object.entries(horses.horses)){
              for (let [id, report] of Object.entries(reports)){
                //Use the local report date to sort it in a new localtime index
                let localReportDate = timestampToDateObject.value(report.timestamp);
                let localReportDayString = localReportDate.format('DD.MM.YYYY');
                let reportType = store.getters['reports/getReportTypeById'](report.type);

                if ((localReportDate.format('MM.YYYY') === selectedMonth.value.month) && /* Only show the report when it is in the current month */
                      !(reportIdsBeingUpdated.includes(id)) && /* Hide reports that are currently being updated */
                      reportType && isReportTypeVisibleForExistingReports(reportType.type)){
                  if (!(localReportDayString in newIndex)) {
                    newIndex[localReportDayString] = {
                      count: 0,
                      horses: {}
                    };
                  }
                  if (!(horse in newIndex[localReportDayString].horses)) {
                    newIndex[localReportDayString].horses[horse] = {};
                  }

                  newIndex[localReportDayString].horses[horse][id] = report;
                  newIndex[localReportDayString].count++;
                }
              }
            }
          }
        }
      }

      return newIndex;
    });

    const filteredUploadStatusAndMetadata = computed(() => {
      let newIndex = {};
      let usedReportTypeIDs = {};
      let status = store.getters['reports/getReportUploadStatusUnfinished'];

      if (selectedDay.value != null){
        let selectedDayObject = dayjs.utc(selectedDay.value, 'DD.MM.YYYY');
        let selectedTimespanDayObject = (selectedTimespanDay.value != null) ? dayjs.utc(selectedTimespanDay.value, 'DD.MM.YYYY') : null;

        for (let [index, report] of Object.entries(status)){
          if (report.details != null && report.details.timestamp != null) {
            let dateTime = timestampToDateObject.value(report.details.timestamp);
            let day = dateTime.format('DD.MM.YYYY');
            let month = dateTime.format('MM.YYYY');
            let reportType = store.getters['reports/getReportTypeById'](report.details.type);
            let tempId = `U${index}`;

            if ((selectedTimespanDayObject == null && dateTime.isSame(selectedDayObject, 'day')) || /* Only show the report when it is on the selected day */
                (selectedTimespanDayObject != null && dateTime.isBetween(selectedDayObject, selectedTimespanDayObject, 'day', '[]'))) { /* Or if a range is specified, in the filtered range */
              
              //Save this reports metadata
              if (reportType != null) {
                if (!(reportType.main_category in usedReportTypeIDs)) usedReportTypeIDs[reportType.main_category] = {};
                if (!(reportType.descriptor in usedReportTypeIDs[reportType.main_category])) usedReportTypeIDs[reportType.main_category][reportType.descriptor] = [];
                if (!(usedReportTypeIDs[reportType.main_category][reportType.descriptor].includes(report.details.type))) usedReportTypeIDs[reportType.main_category][reportType.descriptor].push(report.details.type);
              }
              
              //Applying filters
              if (reportType && isReportTypeVisibleForExistingReports(reportType.type) && 
                  applyReportFilters.value(null, report.details, reportType)){
              
                //Add to new index, piece by piece if not yet existing
                if (!(month in newIndex)) {
                  newIndex[month] = { days: {} };
                }
                if (!(day in newIndex[month].days)) {
                  newIndex[month].days[day] = {
                    count: 0,
                    horses: {}
                  };
                }

                let horse;
                if (report.details == null || report.details.horse == null){
                  horse = null;
                } else {
                  horse = report.details.horse;
                }

                if (!(horse in newIndex[month].days[day].horses)) {
                  newIndex[month].days[day].horses[horse] = {};
                }

                newIndex[month].days[day].count++;
                newIndex[month].days[day].horses[horse][tempId] = {id: index, ...report.details, status: _.omit(report, 'details')};
              }
            }
          }
        }
      }
      
      return { index: newIndex, reportTypes: usedReportTypeIDs};
    });

    const calendarMonthUploadStatus = computed(() => {
      let newIndex = {};
      let status = store.getters['reports/getReportUploadStatusUnfinished'];

      for (let [index, report] of Object.entries(status)){
        if (report.details != null && report.details.timestamp != null) {
          let dateTime = timestampToDateObject.value(report.details.timestamp);
          let day = dateTime.format('DD.MM.YYYY');
          let month = dateTime.format('MM.YYYY');
          let reportType = store.getters['reports/getReportTypeById'](report.details.type);

          if (selectedMonth.value != null && selectedMonth.value.month === month) {
            if (reportType && isReportTypeVisibleForExistingReports(reportType.type)){
              if (!(day in newIndex)) newIndex[day] = {
                count: 0,
                horses: {}
              };

              let horse;
              if (report.details == null || report.details.horse == null){
                horse = null;
              } else {
                horse = report.details.horse;
              }

              if (!(horse in newIndex[day].horses)) {
                newIndex[day].horses[horse] = {};
              }

              let tempId = `U${index}`;

              newIndex[day].count++;
              newIndex[day].horses[horse][tempId] = {...report.details, status: _.omit(report, 'details')};
            }
          }
        }
      }
      
      return newIndex;
    });

    const combinedReports = computed(() => {
      let newIndex = _.cloneDeep(calendarMonthReportIndex.value);

      return _.mergeWith(newIndex, calendarMonthUploadStatus.value, (objectValue, srcValue, key) => {
        if (key == 'count') { //Add 'count' together on merge
          if (objectValue == null && srcValue == null) return 0;
          if (objectValue == null) return srcValue;
          if (srcValue == null) return objectValue;
          return objectValue + srcValue;
        }
      });
    });

    const combinedReportTypes = computed(() => {
      let newReportTypes = (filteredReportIndexAndMetadata.value != null && filteredReportIndexAndMetadata.value.reportTypes != null) ? _.cloneDeep(filteredReportIndexAndMetadata.value.reportTypes) : {};

      return _.merge(newReportTypes, (filteredUploadStatusAndMetadata.value != null && filteredUploadStatusAndMetadata.value.reportTypes != null) ? filteredUploadStatusAndMetadata.value.reportTypes : {});
    });

    const availableFilters = computed(() => {
      let availableReportTypes = combinedReportTypes.value;
      let reportTypes = _.mapKeys(availableReportTypes, (types, category) => getLocalizedMainCategoryById(category));
      let horses = _.map(store.getters['horses/getHorses'], (horse) => { 
        return { value: horse.id, localizedName: ((horse && horse.personal_horse_info) ? horse.personal_horse_info.name : horse.id) };
      });

      return {
        'category': { localizedName: i18n.$t('tracking.filters.category'), allowMultiple: true, options: 
          { null: _.map(Object.keys(availableReportTypes), (category) => { return { value: category, localizedName: getLocalizedMainCategoryById(category) };}) }
        },
        'type': { localizedName: i18n.$t('tracking.filters.type'), allowMultiple: true, options: _.mapValues(reportTypes, (reportTypeArray) => {
            return Object.values(_.mapValues(reportTypeArray, (reportTypeIDs, reportTypeDescriptor) => { return { value: reportTypeIDs, localizedName: getLocalizedReportTypeNameByDescriptor(reportTypeDescriptor) };}));
          })
        },
        'horse': { localizedName: i18n.$t('tracking.filters.horse.name'), allowMultiple: true, options: 
          { null: [ 
            ...horses,
            { value: 'null', localizedName: i18n.$t('tracking.filters.horse.not_assigned'), italic: true }
          ]}},
        'location': { localizedName: i18n.$t('tracking.filters.location'), allowMultiple: true, options: 
          { null: _.map(store.getters['reports/getLocationValues'], (value) => { return { value, localizedName: i18n.$t(`report.location.${value}`) };}) }
        },
        'control_examination': { localizedName: i18n.$t('tracking.filters.control_examination.name'), options: { 
          null: [ {value: false, localizedName: i18n.$t('tracking.filters.control_examination.false')}, {value: true, localizedName: i18n.$t('tracking.filters.control_examination.true')} ] }
        }
      };
    });

    const DYNAMIC_FILTERS = ['category', 'type'];

    const activeFilters = ref({});

    //Remove all the filters that are not available anymore, when they change
    watch(availableFilters, (newFilters, oldFilters) => {
      //Only react when there is something to check and we have a change
      if (newFilters != null && activeFilters.value != null && !_.isEqual(newFilters, oldFilters)) {
        for (let currentFilter of DYNAMIC_FILTERS) {
          if (newFilters[currentFilter] != null && newFilters[currentFilter].options != null && activeFilters.value[currentFilter] != null) {
            activeFilters.value[currentFilter] = _.intersectionWith(activeFilters.value[currentFilter], _.flatten(Object.values(newFilters[currentFilter].options)), (filter, availableFilterOption) => _.isEqual(filter, availableFilterOption.value));
            if (Array.isArray(activeFilters.value[currentFilter]) && activeFilters.value[currentFilter].length == 0) {
              delete activeFilters.value[currentFilter];
            }
          }
        }
      }
    }, {deep: true});

    const availableUnusedFilters = computed(() => {
      return _.omit(availableFilters.value, Object.keys(activeFilters.value));
    });

    const combinedFilteredReports = computed(() => {
      let newIndex = (filteredReportIndexAndMetadata.value != null && filteredReportIndexAndMetadata.value.index != null) ? _.cloneDeep(filteredReportIndexAndMetadata.value.index) : {};

      return _.mergeWith(newIndex, (filteredUploadStatusAndMetadata.value != null && filteredUploadStatusAndMetadata.value.index != null) ? filteredUploadStatusAndMetadata.value.index : {}, (objectValue, srcValue, key) => {
        if (key == 'count') { //Add 'count' together on merge
          if (objectValue == null && srcValue == null) return 0;
          if (objectValue == null) return srcValue;
          if (srcValue == null) return objectValue;
          return objectValue + srcValue;
        }
      });
    });

    const combinedFilteredReportCount = computed(() => {
      if (combinedFilteredReports.value != null) {
        let sum = _.sum(_.map(combinedFilteredReports.value, (month) => {
          return _.sum(_.map(month.days, (day) => {
            return day.count;
          }));
        }));
        if (!isNaN(sum)) return sum;
      }
      return 0;
    });

    //Get and set the currently selected date that is edited, or none if nothing is selected
    const currentlySelectingDay = computed({
      get: () => {
        if (isSelectingDate.value) {
          return selectedDay.value;
        } else if (isSelectingTimespanDate.value) {
          return selectedTimespanDay.value;
        } else {
          return null
        }
      },
      set: newValue => {
        if (isSelectingDate.value) {
          selectedDay.value = newValue;
          isSelectingDate.value = false;
        } else if (isSelectingTimespanDate.value) {
          selectedTimespanDay.value = newValue;
          isSelectingTimespanDate.value = false;
        }
      }
    });

    const selectedMonthMatchesDay = computed(() => {
      if (selectedDay.value != null && selectedMonth.value != null && 'month' in selectedMonth.value) {
        return dayjs(selectedDay.value, 'DD.MM.YYYY').format('MM.YYYY') === selectedMonth.value.month;
      } else {
        return false;
      }
    });

    const dayToLocalFormat = computed(() => {
      return (day) => {
        if (isReady.value) {
          return dayjs(day, 'DD.MM.YYYY').locale(dayjsLocale.value).format('LL');
        } else {
          return dayjs(day, 'DD.MM.YYYY').format('LL');
        }
      }
    });

    const dayToLocalFormatShort = computed(() => {
      return (day) => {
        if (isReady.value) {
          return dayjs(day, 'DD.MM.YYYY').locale(dayjsLocale.value).format('L');
        } else {
          return dayjs(day, 'DD.MM.YYYY').format('L');
        }
      }
    });

    const monthToLocalFormat = computed(() => {
      return (month) => {
        if (isReady.value) {
          return dayjs(month, 'MM.YYYY').locale(dayjsLocale.value).format('MMMM YYYY');
        } else {
          return dayjs(month, 'MM.YYYY').format('MMMM YYYY');
        }
      }
    });

    const selectedMonth = computed({
      get: () => store.getters['reports/getSelectedMonth'],
      set: newValue => {
        store.dispatch('reports/updateSelectedMonth', newValue);
      }
    });

    const navigateToReport = function(reportId){
      let currentRoute = router.currentRoute.value;
      //If we are already navigated to a report route, replace it, else push it
      if (currentRoute.name === 'view-report') {
        router.replace({name: 'view-report', params: { id: reportId }});
      } else {
        router.push({name: 'view-report', params: { id: reportId }});
      }
    }

    const refreshReportIndex = function(refresher = null){ //TODO Add automatic refresh in an interval!
      store.dispatch('reports/fetchReportIndex')
        .then(() => {
          if (refresher != null){
            refresher.complete();
          }
        })
        .catch(error => {
          if (refresher != null){
            refresher.cancel();
          }
          apiErrorToast(i18n, error);
        });
    }

    const getUploadStatusDesign = function(status){
      return store.getters['reports/getUploadStatusDesign'](status);
    }

    const blurOnEnter = function(event) {
      if (event.keyCode === 13) event.target.blur();
    }

    const showUploadStatus = function(){
      openUploadStatusModal(uploadModalComponent);
    }

    onMounted(() => {
      refreshReportIndex();
    });

    return {
      i18n,
      router,
      selectedDay,
      selectedTimespanDay,
      currentlySelectingDay,
      dayToLocalFormat,
      dayToLocalFormatShort,
      monthToLocalFormat,
      sortDate,
      sortByTimestamp,
      selectedMonth,
      selectedMonthMatchesDay,
      store,
      timestampToDateTime,
      getLocalizedMainCategory,
      getLocalizedReportTypeName,
      getHorseName,
      navigateToReport,
      refreshReportIndex,
      combinedReports,
      combinedFilteredReports,
      getUploadStatusDesign,
      showUploadStatus,
      isSelectingDate,
      isSelectingTimespanDate,
      isOffline,
      toggleSelectDate,
      toggleSelectEndDate,
      getFilterIcon,
      getLocalizedFilterName,
      combinedFilteredReportCount,
      availableFilters,
      availableUnusedFilters,
      activeSearch,
      activeFilters,
      removeFilter,
      editFilter,
      blurOnEnter,
      calendarNumber,
      calendar,
      closeCircle,
      search,
      filter
    };
  }
}
</script>

<style scoped>
/* Fix to show the refresher behind the content */
#custom-page {
  background: var(--ion-background-color, #fff);
}
ion-content { 
  --background: transparent;
}


ion-card {
  margin-top: 10px;
}

.horse-name-title {
  font-size: 1.25em;
}

.current-date {
  font-size: 1.25em;
  font-weight: bold;
  text-align: center;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}

.current-month {
  font-size: 1.5em;
  --background: var(--ion-background-color);
  text-align: center;
  color: var(--ion-color-primary)
}

#no-entries-hint {
  color: var(--ion-color-medium);
  font-weight: bold;
  text-align: center;
}

ion-card-content {
  background-color: var(--ion-card-background);
}

.report-entry-item {
  --padding-start: 0px;
  --background: var(--ion-card-background);
}

.report-entry-item ion-label {
  margin-left: 8px;
}

.wrap {
  text-overflow: unset;
  height: auto;
  white-space: normal;
}

.upload-progress-button {
  height: 2.5em;
  width: 2.5em;
  --padding-bottom: 0px;
  --padding-top: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  font-size: inherit;
}

.upload-progress {
  width: 2.5em;
  height: 2.5em;
}

.filter-container {
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 100%;
}

.chip-container {
  margin: 0px;
  padding: 0px;
  flex-grow: 1;
  flex-basis: 0px;
  flex-shrink: 1;
  display: flex;
  flex-flow: row wrap;
  width: 0%;
}

.ios .filter-container {
  padding-right: 16px;
  padding-left: 16px;
}

.date-chip.active {
  color: var(--ion-color-primary-contrast, 0, 0, 0);
  background: rgba(var(--ion-color-primary-rgb, 0, 0, 0), 0.7);
}

.calendar {
  transition: max-height 0.4s ease-in-out;
  max-height: 450px;
}

.calendar.hidden {
  max-height: 0px;
}

ion-chip {
  /* Disable select on ion-chip texts */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

ion-chip ion-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chip-icon {
  padding: 5px 5px 5px 0px;
  margin-right: 0px;
}

.chip-close {
  padding: 5px 0px 5px 5px;
  margin-left: 0px;
  height: 1em;
  width: 1em;
  min-width: 1em;
}

hr {
  border: 2px solid var(--ion-color-medium-light);
  background: var(--ion-color-medium-light);
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.filter-edit-button {
  height: 32px;
  width: 32px;
  font-size: 1.5em;
  --padding-start: 6px;
  --padding-end: 6px;
  --padding-top: 3px;
}

.filter-edit-button b {
  font-size: 0.7em;
  right: -27%;
  bottom: 12%;
  position: absolute;
  padding: 0px;
  margin: 0px;
}

.ios .filter-edit-button b {
  font-size: 0.6em;
  right: -15%;
  bottom: 12%;
}

.chip-filter-name {
  font-weight: 250;
}

</style>