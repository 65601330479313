import { ref, watch, provide, inject } from "vue";

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday'; //To calculate the weekday offsets
import localeData from 'dayjs/plugin/localeData'; //To get the short localized weekdays and Month names
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat'; //To allow parsing with a custom format
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';

const configureDayjs = config => ({
  dayjsLocale: config.localeRef,
  activeLocaleData: ref(dayjs.localeData()), //Locale instance for computed properties that are not dependant on any already localized dates
  importedLocales: config.importedLocales,
  timezone: dayjs.tz.guess(),
  localeImportPromise: config.localeImportPromise,
  isReady: ref(false),
  dayjs: dayjs
});

const updateLocale = function(container, newLocale){
  container.dayjs.locale(newLocale); //Set locale globally for all new calls. If the locale is not loaded or not implemented, it will fallback to en silently
  container.activeLocaleData.value = container.dayjs.localeData();
}

const dayjsSymbol = Symbol();

export function provideDayjs(dayjsConfig) {
  dayjsConfig.importedLocales = {};

  const importLocales = async function() {
    //Import all needed locales dynamically
    for (let language of dayjsConfig.availableLanguages){
      dayjsConfig.importedLocales[language.lang] = await import('dayjs/locale/' + language.lang + '.js');
    }

    return dayjsConfig.importedLocales;
  };

  //Save execution state of the imports in a variable as a promise
  dayjsConfig.localeImportPromise = importLocales();
  
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);
  dayjs.extend(timezone);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isBetween);
  dayjs.extend(relativeTime);

  dayjs.tz.setDefault("Europe/Berlin");

  const dayjsContainer = configureDayjs(dayjsConfig);

  //After promise is resolved, set initial locale
  dayjsConfig.localeImportPromise.then(() => {
    updateLocale(dayjsContainer, dayjsContainer.dayjsLocale.value);
    dayjsContainer.isReady.value = true;
  });

  //update locale if reference changes
  watch(() => dayjsContainer.dayjsLocale.value, (newLocale) => {
    updateLocale(dayjsContainer, newLocale);
  });

  provide(dayjsSymbol, dayjsContainer);
}

export function useDayjs() {
  const dayjsContainer = inject(dayjsSymbol);
  if (!dayjsContainer) throw new Error("dayjs has to be provided first!");

  return dayjsContainer;
}
