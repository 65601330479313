<template id="iframe-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content scroll-y="false">
      <iframe :src="href" :title="title"></iframe>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';

import { useI18n } from "@/utils/i18n";

const IFrameModal = defineComponent({
  name: 'IFrameModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent },
  props: {
    'title': String,
    'href': String
  },
  setup() {
    const i18n = useI18n();

    const closeModal = function(){
      modalController.dismiss();
    }

    return { i18n, closeModal };
  }
});

export async function openIFrameModal(component, title, href){
  if (component != null && title != null && href != null) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          title,
          href
        },
      })
    modal.present();
    return modal.onDidDismiss();
  }
}

export default IFrameModal;
</script>

<style scoped>
iframe {
    border: none;
    overflow: scroll;
    width: 100%;
    height: 100%;
}

.dark iframe {
  filter: hue-rotate(160deg) invert() brightness(90%);
}
</style>