<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom">
        <ion-tab-button ref="analysisTab" tab="analysis" href="/health/analysis" @click="router.push('/health/analysis')">
          <ion-icon :icon="searchCircle" />
          <ion-label>{{ i18n.$t('analysis.title') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button class="tracking-tab" tab="tracking" href="/health/tracking" @click="router.push('/health/tracking')">
          <ion-icon :icon="calendar" />
          <ion-label>{{ i18n.$t('tracking.title') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="quick-analysis" href="/health/quick-analysis" @click="router.push('/health/quick-analysis')">
          <ion-icon :icon="ai_analyse" />
          <ion-label>{{ i18n.$t('quick-analysis.title') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { calendar, searchCircle } from 'ionicons/icons';
import ai_analyse from '@/assets/icons/ai_analyse.svg';

import { useI18n } from "@/utils/i18n";
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    const i18n = useI18n();

    const analysisTab = ref(null);

    const smallScreenQuery = window.matchMedia("(max-width: 992px)");

    const router = useRouter();

    smallScreenQuery.addEventListener('change', (query) => { //Navigate away when screen size changes to big one and we are on the removed tab
      if (!query.matches && router.currentRoute.value.name === 'tracking')
        analysisTab.value.$el.click(); //Use workaround as a click on the tab, since ion-tabs does not expose method select as stated
        //$el has to be used with ionic to get the element itself
    });

    return {
      i18n,
      calendar,
      ai_analyse,
      searchCircle,
      analysisTab,
      router
    }
  }
}
</script>

<style scoped>
  @media (min-width: 992px) {
    .tracking-tab {
      display: none;
    }
  }
</style>
