<template id="upload-status-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t("report.upload-status.title") }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-item lines="none">
      <ion-button class="retry-button" expand="block" color="success" @click="triggerResync()">
        <ion-icon slot="start" :icon="refresh"></ion-icon>
        {{ i18n.$t("report.upload-status.retry") }}
      </ion-button>
    </ion-item>
    <ion-item class="upload-queue" lines="none">
      <b>{{ i18n.$t("report.upload-status.upload-queue") }}{{ (Object.keys(uploadStatus).length >= 0) ? ` (${Object.keys(uploadStatus).length})` : '' }}:</b>
    </ion-item>
    <ion-content>
      <ion-list>
        <ion-item class="no-uploads" lines="none" v-if="Object.keys(uploadStatus).length <= 0">
          {{ i18n.$t("report.upload-status.no-uploads") }}
        </ion-item>
        <ion-item v-for="(status, key) in uploadStatus" :key="key"
          lines="full"
          class="report-entry-item"
          detail
          button
          @click="navigateToReport(`U${key}`)">
          <div class="delete-button-container">
            <ion-button slot="start" fill="clear" color="danger" class="delete-button" expand="block" @click.stop="openDeleteConfirmation(key, status)">
              <ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
            </ion-button>
          </div>
          <ion-label>
            <h2>{{ getLocalizedMainCategory(status.details.type) }}</h2>
            <h3>{{ timestampToDateTime(status.details.timestamp) }}</h3>
            <h3 :style="'color: var(' + getUploadStatusDesign(status).color + ');'">
              {{ i18n.$t("report.upload-status." + getUploadStatusDesign(status).text) }}
            </h3>
            <p class="wrap">{{ getLocalizedReportTypeName(status.details.type) }}</p> 
          </ion-label>
          <CircularProgress
            class="upload-progress"
            :style="'--color: var(' + getUploadStatusDesign(status).color + ');'"
            :progress="(status.progress >= 0) ? status.progress : 0">
            <ion-icon :icon="getUploadStatusDesign(status).icon"></ion-icon>
          </CircularProgress>
          
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonList, IonItem, IonLabel, IonIcon, modalController, alertController } from '@ionic/vue';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { cloudUpload, cloudOffline, alert, trashOutline, refresh } from 'ionicons/icons';

import CircularProgress from '@/components/CircularProgress.vue';

import { useI18n } from "@/utils/i18n";

import { useDayjs } from '@/utils/dayjs';

const UploadStatusModal = defineComponent({
  name: 'UploadStatusModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonList, IonItem, IonLabel, IonIcon, CircularProgress },
  setup() {
    const i18n = useI18n();

    const { dayjs, dayjsLocale, isReady, timezone } = useDayjs();

    const router = useRouter();

    const store = useStore();

    const closeModal = function(){
      modalController.dismiss();
    }

    const uploadStatus = computed(() => store.getters['reports/getReportUploadStatusUnfinished']);

    const getUploadStatusDesign = function(status){
      return store.getters['reports/getUploadStatusDesign'](status);
    }

    const timestampToDateTime = computed(() => {
      return (timestamp) => {
        if (isReady.value) {
          return dayjs.utc(timestamp).locale(dayjsLocale.value).tz(timezone).format('LL LTS');
        } else {
          return dayjs.utc(timestamp).tz(timezone).format('LL LTS');
        }
      }
    });

    const getLocalizedMainCategory = function(reportTypeId){
      let categoryNames = store.getters['reports/getMainCategoryNamesOfReportTypeId'](reportTypeId);
      if (categoryNames) {
        return categoryNames[i18n.locale.value];
      } else {
        return null;
      }
    }

    const getLocalizedReportTypeName = function(reportTypeId){
      let reportType = store.getters['reports/getReportTypeById'](reportTypeId);
      if (reportType && reportType.descriptor) {
        return reportType.descriptor; //TODO Use the translated name here
      } else {
        return null;
      }
    }

    const triggerResync = function(){
      store.dispatch('reports/retryUploads', true).catch((errors) => console.error('Retry failed', errors));
    }

    const openDeleteConfirmation = async function(index, status){
      const alert = await alertController
      .create({
        cssClass: 'delete-confirmation-alert',
        header: i18n.$t('report.upload-status.delete-confirmation.title'),
        message: i18n.$t('report.upload-status.delete-confirmation.message.before') + '<b>' + timestampToDateTime.value(status.details.timestamp) + '</b>' + i18n.$t('report.upload-status.delete-confirmation.message.after'),
        buttons: [
          {
            text: i18n.$t('default_interaction.cancel'),
            role: 'cancel'
          },
          {
            text: i18n.$t('report.upload-status.delete-confirmation.delete'),
            cssClass: 'delete-confirmation-okay',
            handler: () => {
              store.dispatch('reports/removeUploadFromQueue', index).then(() => store.commit('reports/removeReportUploadStatus', index));
            },
          },
        ],
      });
      return alert.present();
    }

    const navigateToReport = function(reportId){
      let currentRoute = router.currentRoute.value;
      //If we are already navigated to a report route, replace it, else push it
      if (currentRoute.name === 'view-report') {
        router.replace({name: 'view-report', params: { id: reportId }});
      } else {
        router.push({name: 'view-report', params: { id: reportId }});
      }
      closeModal();
    }

    return { i18n, closeModal, uploadStatus, getUploadStatusDesign, timestampToDateTime, getLocalizedMainCategory, getLocalizedReportTypeName, triggerResync, openDeleteConfirmation, navigateToReport, cloudUpload, cloudOffline, alert, trashOutline, refresh };
  }
});

export async function openUploadStatusModal(component){
  if (component != null) {
    const modal = await modalController
      .create({
        component
      })
    modal.present();
    return modal.onDidDismiss();
  }
}

export default UploadStatusModal;
</script>

<style>
.delete-confirmation-okay {
  color: var(--ion-color-danger)!important;
}
</style>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-item ion-label * {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.retry-button {
  --padding-start: 20px;
  --padding-end: 20px;
  height: 3em;
  margin: 10px 0px;
  text-transform: none;
  font-weight: bold;
  font-size: 0.8em;
}

.no-uploads {
  --padding-start: 40px;
}

.report-entry-item {
  --padding-start: 0px;
}

.delete-button-container {
  height: 100%;
}

.delete-button {
  --padding-start: 15px;
  --padding-end: 15px;
  height: 100%;
  --padding-top: 0px;
  --padding-bottom: 0px;
  margin: 0px;
}

.upload-progress {
  width: 2.5em;
  height: 2.5em;
}
</style>