<template>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button v-if="isSubpage" default-href="/health"></ion-back-button>
      <ion-button v-else-if="!isSimple" href="/">
        <img src="/assets/icon/favicon.png" alt="App-Logo"/>
      </ion-button>
    </ion-buttons>
    <div id="title">
      <ion-label>
        <ion-icon v-if="!isSimple && isOffline" id="offline-icon" :icon="cloudOfflineOutline"></ion-icon>
        <span v-if="titleIcon" class="title-icon">
          <ion-icon :icon="titleIcon"></ion-icon>
        </span>
        {{ title }}
      </ion-label>
    </div>
    <ion-buttons v-if="!isSimple" slot="end">
      <LanguageMenu></LanguageMenu>
      <ion-menu-button v-if="!isMenuDisabled" menu="settings"></ion-menu-button>
      <!-- If the menu is disabled, show some settings in the toolbar-->
      <ion-button v-else @click="darkModeActive = !darkModeActive">
        <ion-icon slot="icon-only" :icon="darkModeActive ? moon : moonOutline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</template>

<script>
import { IonToolbar, IonLabel, IonButtons, IonBackButton, IonMenuButton, IonButton, IonIcon } from '@ionic/vue';
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import { moon, moonOutline, cloudOfflineOutline } from 'ionicons/icons';

import LanguageMenu from '@/components/LanguageMenu.vue';

export default defineComponent({
  name: 'MainToolbar',
  components: { IonToolbar, IonLabel, IonButtons, IonBackButton, IonMenuButton, IonButton, IonIcon, LanguageMenu },
  props: {
    isSubpage: Boolean,
    isSimple: Boolean,
    isMenuDisabled: Boolean, //Currently not used
    title: String,
    titleIcon: String
  },
  setup(){
    const store = useStore();

    //Used for simple button, current state is visualized. If pressed, preference will be set
    const darkModeActive = computed({
      get: () => store.getters.isDarkModeActive,
      set: newValue => {
        store.dispatch('setDarkModePreference', newValue);
      }
    });

    const isOffline = computed(() => {
      return !(store.getters.isOnline);
    })

    return { darkModeActive, isOffline, moon, moonOutline, cloudOfflineOutline };
  }
});
</script>

<style scoped>
img {
  max-height: 2em;
  margin: 5px;
}

.title-icon {
  height: 100%;
  vertical-align: middle;
  display: inline;
  padding-left: 5px;
  padding-right: 5px;
}

#title {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.ios #title ion-label {
  font-size: clamp(.8em, 4vw, 17px);
  font-weight: 600;
}

#title ion-label {
  width: 100%;
  padding: 0px 10px;
  font-size: clamp(.8em, 4vw, 20px);
  font-weight: 500;
  letter-spacing: 0.0125em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#offline-icon {
  transform: translateY(16%);
  margin-right: 3px;
  font-size: 1em;
  color: var(--ion-color-tertiary, violet);
}
</style>
