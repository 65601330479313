import { api } from '@/utils/api'

const MODULE_VERSION = 1; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

const getDefaultState = () => {
  return {
    version: undefined,
    horses: {},
    selectedHorse: null
  }
};

export default {
  namespaced: true,

  state: getDefaultState,
  getters: {
    getHorses (state) {
      return state.horses;
    },
    getHorseById (state) {
      return function(id) {
        return state.horses[id];
      }
    },
    getSelectedHorse (state) {
      return state.selectedHorse;
    }
  },
  mutations: {
    setHorses (state, newHorses) {
      state.horses = newHorses;
    },
    addHorse (state, {id, newHorse}) {
      state.horses[id] = newHorse;
    },
    setSelectedHorse (state, newHorse) {
      state.selectedHorse = newHorse;
    },
    checkVersion (state) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState)){
            state[key] = undefined;
          }
        }
        state.version = MODULE_VERSION;
      }
    },
    clearPersonalData (state) {
      let defaultState = {};
      Object.assign(defaultState, getDefaultState());
      state.horses = defaultState.horses;
      state.selectedHorse = defaultState.selectedHorse;
    }
  },
  actions: {
    fetchHorses (context) {
      return new Promise((resolve, reject) => {
        api
        .get('/horses', {
          //TODO Implement Filter to only fetch ones newer (updated_at) than latest fetch
        })
        .then(response => { //Add to object with id as index
          for (let horse of response.data){
            context.commit('addHorse', {id: horse.id, newHorse: horse});
          }
          
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    createNewHorse (context, horseInfo) {
      return new Promise((resolve, reject) => {
        api
        .post('/horses', {
          'personal_horse_info': horseInfo
        })
        .then(response => {
          let horse = response.data;
          context.commit('addHorse', {id: horse.id, newHorse: horse}); //TODO Keep files in mind. Result does not contain files.
          resolve(horse);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    updateHorse (context, {id, horseInfo, avatar}) {
      console.log(avatar); //TODO Add avatar file as FormData to the horse
      return new Promise((resolve, reject) => {
        api
        .put(`/horses/${id}`, {
          'personal_horse_info': horseInfo
        })
        .then(response => { 
          let horse = response.data;
          context.commit('addHorse', {id: horse.id, newHorse: horse}); //TODO Keep files in mind. Result does not contain files.
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    updateSelectedHorse (context, newHorse) {
      context.commit('setSelectedHorse', newHorse);
    }
  }
}