import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import _ from 'lodash';

import skeleton from './modules/skeleton';
import auth from './modules/auth';
import reports from './modules/reports';
import horses from './modules/horses';
import generalFeedback from './modules/general-feedback';

import { api } from '@/utils/api';
import { AUTH_ERRORS } from '@/utils/error';

import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

const UPDATE_INTERVAL_MINS = 2;

const CONSOLE_INTERCEPT_METHODS = [
  'log',
  'info',
  'warn',
  'error'
]

const BLOCKED_SEARCH_STRING = 'blocked';

const MAX_LOG_COUNT = 100;

const logStorage = new Storage({
  name: '__logs',
  driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
});
logStorage.create();

const clearCacheStorage = function() {
  return logStorage.clear();
}

const MODULE_VERSION = 4; //UPDATE VERSION WITH EVERY BREAKING CHANGE OR ATTRIBUTE REMOVAL

const getDefaultState = () => {
  return {
    version: undefined,
    darkModePreference: undefined, //Undefined means to use the automatic device value
    darkModeAutomaticValue: false,
    onlineStatus: true
  }
};

const NON_PERSISTED_PATHS = [];

const modules = {
  skeleton,
  auth,
  reports,
  horses,
  generalFeedback
};

const store = createStore({
  plugins: [createPersistedState({ //TODO Adapt to use Ionic Native Storage
    reducer (newPersistedState) { //Set empty state in store when logged out to not store any sensible information                         
      if(!newPersistedState.auth.token) {              
        return {};
      }
      return _.omit(newPersistedState, NON_PERSISTED_PATHS);
    }
  })], //Saves the state by default in localStorage for the current session
  modules,
  state: getDefaultState,
  getters: {
    isDarkModePreferred (state) {
      return state.darkModePreference;
    },
    isDarkModeActive (state) {
      if (state.darkModePreference !== undefined) { //If a preference for dark mode has been set, this preferred value is used
        return state.darkModePreference;
      } else { //Else the automatic value is used
        return state.darkModeAutomaticValue;
      }
    },
    isOnline (state) {
      return state.onlineStatus;
    },
    getLogs () {
      return async () => {
        let logArray = [];
        
        return logStorage.forEach((value, key) => {
          logArray.push(
            {
              timestamp: key,
              ...value
            }
          );
        }).then(() => logArray);
      }
    }
  },
  mutations: {
    changeDarkModePreference (state, newValue) {
      state.darkModePreference = newValue;
    },
    changeDarkModeAutomaticValue (state, newValue) {
      state.darkModeAutomaticValue = newValue;
    },
    checkVersion (state, moduleKeys) { //Checks the version of this module and sets the state to default, if version is different
      //Used to reset state on breaking changes and to remove attributes that are no longer needed
      if (state.version !== MODULE_VERSION) {
        let defaultState = getDefaultState();
        Object.assign(state, defaultState); //Use setters to not impact any reactive functionality
        //Search for unused attributes and set them undefined, to remove them from the persisted state
        for (let key of Object.keys(state)){
          if (!(key in defaultState || moduleKeys.includes(key))){ //Exclude module keys, because they are handled separately
            state[key] = undefined;
          }
        }
        state.version = MODULE_VERSION;
        //Clear cache too
        clearCacheStorage();
      }
    },
    changeOnlineStatus (state, newStatus) {
      state.onlineStatus = newStatus;
    },
    clearPersonalData () {}
  },
  actions: {
    setDarkModeStatus (context) { //Always called after a change to set it in html
      if (context.state.darkModePreference !== undefined) { //If a preference for dark mode has been set, use this preferred value
        document.body.classList.toggle('dark', context.state.darkModePreference);
      } else { //Else set it from the automatic value
        document.body.classList.toggle('dark', context.state.darkModeAutomaticValue);
      }
    },
    setDarkModePreference (context, newValue) {
      context.commit('changeDarkModePreference', newValue);
      context.dispatch('setDarkModeStatus');
    },
    setDarkModeAutomaticValue (context, newValue) { //Call at least once on start, to set the status
      context.commit('changeDarkModeAutomaticValue', newValue);
      context.dispatch('setDarkModeStatus');
    },

    setOnline (context) {
      context.commit('changeOnlineStatus', true);
    },
    setOffline (context) {
      context.dispatch('reports/setAllReportUploadsUnsuccesful');
      context.commit('changeOnlineStatus', false);
    },

    clearCaches (context) {
      return Promise.all([clearCacheStorage(), context.dispatch('reports/clearCaches')]);
    },

    clearAllPersonalData (context) {
      //Check every version of every module
      const moduleKeys = Object.keys(modules);
      for (let module of moduleKeys){
        context.commit(`${module}/clearPersonalData`);
      }
      context.commit('clearPersonalData');
    },

    //Handler to invalidate the token, if the error is authentication related and to clear caches when the error is caused by being blocked
    handleAPIError (context, error) {
      //Try to search if the message contains an indicator of being blocked to clear the users caches
      try {
        if (error.response && error.response.data && error.response.data.message) {
          if (Array.isArray(error.response.data.message)) {
            for (let message of error.response.data.message){
              if (message.messages && Array.isArray(message.messages)) {
                for (let messageObject of message.messages){
                  if (messageObject.message && messageObject.message.includes(BLOCKED_SEARCH_STRING)) context.dispatch('clearCaches');
                }
              } else {
                if (message.includes(BLOCKED_SEARCH_STRING)) context.dispatch('clearCaches');
              }
            }
          } else {
            if (error.response.data.message.includes(BLOCKED_SEARCH_STRING)) context.dispatch('clearCaches');
          }
        } else if (error.message) {
          if (error.message.includes(BLOCKED_SEARCH_STRING)) context.dispatch('clearCaches');
        }
      } catch (error) {
        console.error('Could not parse error', error);
      }

      if(error && error.response && error.response.status && AUTH_ERRORS.includes(error.response.status)){
        context.dispatch('auth/logout').catch(() => {});
      }
    }
  }
})

//Check every version of every module
const moduleKeys = Object.keys(modules);
store.commit('checkVersion', moduleKeys);
for (let module of moduleKeys){
  store.commit(`${module}/checkVersion`);
}

//Intercept every request to set the authentication header automatically based on the current presence of it
api.interceptors.request.use((config) => {
  const token = store.getters['auth/getToken'];

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

//Intercept every response to sanitize it
api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  store.dispatch('setOnline');
  //TODO Sanitize all API returns
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if(error && error.response && error.response.status){
    store.dispatch('handleAPIError', error);
    throw error;
  } else if (error && (!error.response || !error.response.status)) {
    //Network error, no response or status set
    store.dispatch('setOffline');
    throw Error('unreachable');
  } else {
    throw error;
  }
});

function checkOnlineStatus(){ //TODO Not really necessary once other functions regularly check for updates of new data, but maybe also good still keeping it with a higher interval
  //Just get the header of the api main page to indicate online status - least amount of data
  api.head('').catch(() => {}); //TODO Also working in the background for sync?
}

setInterval(checkOnlineStatus, UPDATE_INTERVAL_MINS * 60 * 1000); //TODO Increase the interval every time to a ceratin threshold when offline

//Capture console calls and save them - Intercept original methods with own versions
for (let method of CONSOLE_INTERCEPT_METHODS) {
  let originalName = '_std_' + method; //Save original function
  console[originalName] = console[method].bind(console);
  
  console[method] = function(){
    let logArray = Array.from(arguments);
    //When the rolling storage has hit its limit, remove the oldest one
    logStorage.length()
      .then((logCount) => {
        if (logCount >= MAX_LOG_COUNT) {
          return logStorage.keys();
        } else {
          return null;
        }
      })
      .then((keys) => {
        if (keys != null && keys.length > 0) {
          let removePromiseChain = Promise.resolve();
          for (let key of keys.slice(0, (keys.length - MAX_LOG_COUNT) + 1)) {
            removePromiseChain = removePromiseChain.then(() => logStorage.remove(key));
          }
          return removePromiseChain;
        } else {
          return null;
        }
      })
      .catch(() => null) //Continue on error
      .then(() => {
        //Add the current log to the storage, after all is done
        return logStorage.set(Date.now().toString(), {
          type: method,
          log: logArray.map((logValue) => { //Convert some unserializable objects
            if (logValue === null) {
              return 'null';
            } else if (logValue === undefined) {
              return 'undefined';
            } else if (typeof logValue === 'object' && !Array.isArray(logValue) && !(logValue instanceof Error)) {
              let logValueObject = {};
              for (let key in logValue){
                logValueObject[key] = (logValue[key] != null && logValue[key].toString) ? logValue[key].toString() : 'null';
              }
              return logValueObject;
            }
            return logValue;
          })
        });
      })
      .catch((error) => {
        console['_std_error'].apply(console, ['Could not save log properly, falling back to string.', error]);
        //If it fails, try to add a string representation
        return logStorage.set(Date.now().toString(), {
          type: method,
          log: logArray.map((logValue) => logValue.toString())
        });
      })
      .catch((error) => {
        //If still an exception, add this info to the store
        return logStorage.set(Date.now().toString(), {
          type: method,
          log: ['Error when saving this log entry', error.toString()]
        });
      })
      
    //Call the original function to still show it in the console
    console[originalName].apply(console, arguments);
  }
}

function handleUncaughtError() {
  console.error(...Array.from(arguments));
  return false; //Continue to error handler
}

//Handle uncaught errors and promise rejections
window.addEventListener('error', handleUncaughtError, true);
window.addEventListener('unhandledrejection', handleUncaughtError, true);

export default store