<template>
  <!-- Don't set disabled, or else it will block interaction on the whole screen. Only close it when disabling -->
  <ion-menu side="end" menu-id="settings" content-id="pane" swipe-gesture="false">
    <!-- Header with User Details is only relevant for logged in users, so it is disabled if not authenticated -->
    <ion-header>  
      <ion-toolbar class="user-info" color="tertiary">
        <div class="welcome-header">
          <ion-label class="welcome-label">{{ i18n.$t("settings.welcome") }}</ion-label>
          <ion-buttons>
            <ion-button class="settings-button" fill="clear" shape="round" disabled> <ion-icon slot="icon-only" :icon="settingsOutline"></ion-icon></ion-button>
          </ion-buttons>
        </div>
        <ion-label>
          <span v-if="isAuthenticated">{{ userIdentification || '' }}</span>
          <span v-else class="login-prompt" @click="navigateTo('/login')">{{ i18n.$t("login.login") }} <ion-icon :icon="logInOutline"></ion-icon></span>
        </ion-label>
        
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <!-- Only enable minimum settings if not logged in -->
      <ion-list v-if="isUserPresent">
        <ion-item :detail="false" button @click="store.dispatch('auth/logout').catch(() => {})">
          <ion-icon slot="start" :icon="logOutOutline"></ion-icon>
          <ion-label>{{ i18n.$t("login.logout") }}</ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item lines="inset">
          <!-- Dark mode supports one of three states: AUTO (Use device preference), ON, OFF -->
          <ion-icon slot="start"
            :icon="darkModeActive ? moon : moonOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.dark_mode") }}</ion-label>
          <ion-select :value="darkModePreference" interface="popover" @ionChange="darkModePreference = $event.target.value">
            <ion-select-option :value="undefined">{{ i18n.$t("settings.auto") }}</ion-select-option>
            <ion-select-option :value="true">{{ i18n.$t("settings.on") }}</ion-select-option>
            <ion-select-option :value="false">{{ i18n.$t("settings.off") }}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item v-if="isAuthenticated" button :detail="false" @click="showUploadStatus()">
          <ion-icon slot="start" :icon="cloudUploadOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("report.upload-status.title") }}</ion-label>
        </ion-item>
        <ion-list-header>
          {{ i18n.$t("settings.other") }}
        </ion-list-header>
        <ion-item v-if="store.getters['auth/hasPermission']('Tools')" button :detail="false" @click="navigateTo('/tools')">
          <ion-icon slot="start" :icon="constructOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("tools.title") }}</ion-label>
        </ion-item>
        <ion-item button :detail="false" @click="openHrefAsModal('https://anirec.de/agb', i18n.$t('settings.agb'))">
          <ion-icon slot="start" :icon="readerOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.agb") }}</ion-label>
        </ion-item>
        <ion-item button :detail="false" @click="openHrefAsModal('https://anirec.de/privacy', i18n.$t('settings.privacy'))">
          <ion-icon slot="start" :icon="fingerPrint">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.privacy") }}</ion-label>
        </ion-item>
        <ion-item button :detail="false" @click="openHrefAsModal('https://anirec.de/impressum', i18n.$t('settings.impressum'))">
          <ion-icon slot="start" :icon="information">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.impressum") }}</ion-label>
        </ion-item>
        <ion-item button :detail="false" @click="openHrefAsModal('https://anirec.de/ionic-license', i18n.$t('settings.license'))">
          <ion-icon slot="start" :icon="libraryOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.license") }}</ion-label>
        </ion-item>
        <ion-list-header>
          {{ i18n.$t("settings.communication") }}
        </ion-list-header>
        <ion-item v-if="isAuthenticated" button :detail="false" @click="navigateTo('/general-feedback')">
          <ion-icon slot="start" :icon="chatboxEllipsesOutline">
          </ion-icon>
          <ion-label>{{ i18n.$t("general-feedback.title") }}</ion-label>
        </ion-item>
        <ion-item :detail="false" href="https://anirec.de/mail/info">
          <ion-icon slot="start" :icon="at">
          </ion-icon>
          <ion-label>{{ i18n.$t("settings.contact") }}</ion-label>
        </ion-item>
      </ion-list>
      <div id="app-version">
        <span @click="showLogs()"> {{ i18n.$t("settings.app_version") }}: {{ appVersion }} </span>
      </div>
    </ion-content>
  </ion-menu>
</template>

<script>
import { IonMenu, IonHeader, IonContent, IonToolbar, IonList, IonListHeader, IonItem, IonSelect, IonSelectOption, IonLabel, IonIcon, IonButtons, IonButton, menuController } from '@ionic/vue';
import { defineComponent, computed, watch } from 'vue';
import { useStore } from 'vuex';

import { moon, moonOutline, logOutOutline, constructOutline, readerOutline, fingerPrint, information, at, libraryOutline, cloudUploadOutline, chatboxEllipsesOutline, settingsOutline, logInOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";
import { useRouter } from 'vue-router';

import {openIFrameModal, default as modalComponent} from '@/components/IFrameModal.vue';
import {openUploadStatusModal, default as uploadModalComponent} from '@/components/UploadStatusModal.vue';
import {openLogModal, default as logModalComponent} from '@/components/LogModal.vue';

export default defineComponent({
  name: 'SettingsMenu',
  props: {
    isAuthenticated: Boolean,
    isUserPresent: Boolean,
    userIdentification: String
  },
  components: {
    IonMenu, IonHeader, IonContent, IonToolbar, IonList, IonListHeader, IonItem, IonSelect, IonSelectOption, IonLabel, IonIcon, IonButtons, IonButton
  },
  setup(props){
    const store = useStore();

    const i18n = useI18n();

    const router = useRouter();

    const appVersion = process.env.VUE_APP_VERSION;

    //Close the menu when authentication changes, because it results in navigation
    watch(
      () => props.isAuthenticated,
      () => {
        menuController.close('settings');
      }
    )

    const darkModeActive = computed(() => store.getters.isDarkModeActive );
    const darkModePreference = computed({
      get: () => store.getters.isDarkModePreferred,
      set: newValue => {
        if (newValue === true || newValue === false){
          store.dispatch('setDarkModePreference', newValue);
        } else {
          store.dispatch('setDarkModePreference', undefined)
        }
      }
    })

    const openHrefAsModal = function(href, title){
      menuController.close('settings');
      openIFrameModal(modalComponent, title, href);
    }

    const navigateTo = function(href){
      menuController.close('settings');
      router.push(href)
    }

    const showUploadStatus = function(){
      menuController.close('settings');
      openUploadStatusModal(uploadModalComponent);
    }

    const showLogs = function(){
      menuController.close('settings');
      openLogModal(logModalComponent);
    }

    return { store, i18n, darkModeActive, darkModePreference, appVersion, moon, moonOutline, logOutOutline, constructOutline, readerOutline, fingerPrint, information, at, libraryOutline, cloudUploadOutline, chatboxEllipsesOutline, settingsOutline, logInOutline, router, openHrefAsModal, navigateTo, showUploadStatus, showLogs }
  }
});
</script>

<style scoped>

.user-info {
  padding: 20px;
  padding-top: 20px!important;
}

.welcome-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcome-label {
  font-size: 1.5em;
  font-weight: bold;
}

ion-title {
  padding: 0px;
}

.login-prompt {
  cursor: pointer;
}

.login-prompt ion-icon {
  vertical-align: bottom;
  font-size: 1.5em;
}

ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-content::part(scroll) {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#app-version {
  width: 100%;
  font-size: 0.8em;
  flex: 4;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#app-version > * {
  padding: 20px 10px;
  width: 100%;
  text-align: end;
}
</style>
